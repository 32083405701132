import React
  from 'react';

import './MultiButtonChoiceBox.scss';
import QuestionBox
  from '../QuestionBox/QuestionBox';
import TextBox
  from '../../Basic/TextBox/TextBox';
import ImageBox
  from '../../Basic/ImageBox/ImageBox';
import ButtonBox
  from '../../Basic/ButtonBox/ButtonBox';
import AudioPlayButton
  from '../AudioPlayButton/AudioPlayButton';
import { COLORS }
  from '../../../Style/Style';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

/**
* @class - MultiButtonChoiceBox - class
* @{props} - {Object}
  * question - Object -
  * recordAnswer - function
  * interactionsSlides - slice of state
* @return { JSX Object }
*/

const MultiButtonChoiceBox = (props) => {
  const { section, lessonId, questions, currentQuestionIndex, quizProgress } = props;
  const currentQuestion = questions[currentQuestionIndex];

  const toggleClickHandler = () => {
    gaEvent('multiple_choice_option_select', { section_id: section.id });
    return (e) => {
      recordProgress(currentQuestion.options[Number(e.currentTarget.dataset.id)]);
    };
  };

  const recordProgress = (userOption) => {
    const answer = {
      [lessonId]: {
        [section.id]: {
          [currentQuestion.id]: {
            0: {
              id:         userOption.id,
              text:       userOption.text,
              is_correct: userOption.text === currentQuestion.answers[0].text
            }
          }
        }
      }
    };

    props.recordQuizAnswer(answer);
  };

  const pinUnansweredRender = () => {
    return (
      props.pinUnanswered &&
      !quizProgress?.[section.id]?.[currentQuestion.id]?.[0] &&
      (
        <div className="Pin-Unanswered">
          <ImageBox className="Pin-Unanswered-Img" src="pin-unanswered.svg" />
        </div>
      )
    )
  }

  let buttons;

  if(currentQuestion.options.length === 0 || currentQuestion.answers.length === 0) {
    buttons = (
      <div
        className="MultiButtonChoiceBox-ButtonBox-container">
        This question is incomplete, missing answer.
      </div>
    );
  } else {
    if (props.revealAnswers && !quizProgress?.[section.id]?.[currentQuestion.id]?.[0]?.is_correct) {
      recordProgress(currentQuestion.options.find((option) => option.text == currentQuestion.answers[0].text));
    }

    buttons = currentQuestion.options.map((option, idx) => {
      let className;
  
      if (quizProgress?.[section.id]?.[currentQuestion.id]?.[0]) {
        if (quizProgress[section.id][currentQuestion.id][0].id === option.id) {
          className = 'TrueOrFalseBox-Button__selected';
        } else {
          className = 'TrueOrFalseBox-Button';
        }
      } else {
        className = 'TrueOrFalseBox-Button';
      }
  
      return (
        <div
          className="MultiButtonChoiceBox-ButtonBox-div"
          key={ option.id }>

          { option.audio_url && <AudioPlayButton src={ option.audio_url }/> }

          <ButtonBox
            className={ className }
            onClick={ toggleClickHandler() }
            text={ option.text }
            id={ idx }/>
        </div>
      );
    });
  }

  return (
    <div className='MultiButtonChoiceBox'>
      { pinUnansweredRender() }
      <div
        className="MultiButtonChoiceBox-QuestionBox">
        {
          !props.isComplianceCourse && (
            !props.small &&
            <div className="FillInTheBlankBox-QuestionBox-Position">
              <TextBox text={currentQuestion.position} />
            </div>
          )
        }

        <QuestionBox question={ currentQuestion }>
          <TextBox
            text={ currentQuestion.text }
            tag='p'
            style={{
              color: COLORS.Blue
            }}/>
        </QuestionBox>
        
        <div style={{ margin: "0 20px" }}>
          {
            props.checkAnswers && quizProgress?.[section.id]?.[currentQuestion.id]?.[0] &&
            (
              quizProgress[section.id][currentQuestion.id][0].is_correct ?
                <i class="fas fa-check icon-correct"></i>
                :
                <i class="fas fa-times icon-wrong"></i>
            )
          }
        </div>
      </div>

      <div className='MultiButtonChoiceBox-ButtonBox-container'>
        { buttons }
      </div>
    </div>
  );
};

export default MultiButtonChoiceBox;
