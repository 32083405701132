import * as APIUtil from '../../../Util/Stats/B2BAdmin/LearnApiUtil';
import ErrorResponseUtil from '../../../Util/ErrorResponseUtil';

export const RECEIVE_B2B_ADMIN_LEARN_STATS = 'RECEIVE_B2B_ADMIN_LEARN_STATS';
export const RECEIVE_B2B_ADMIN_LEARN_STAT_ERRORS = 'RECEIVE_B2B_ADMIN_LEARN_STAT_ERRORS';
export const CLEAR_B2B_ADMIN_LEARN_STATS = 'CLEAR_B2B_ADMIN_LEARN_STATS';

export const receiveLearnStats = (payload) => ({
  type: RECEIVE_B2B_ADMIN_LEARN_STATS,
  payload,
});

export const receiveLearnStatErrors = (errors) => ({
  type: RECEIVE_B2B_ADMIN_LEARN_STAT_ERRORS,
  errors,
});

export const clearLearnStats = (key) => {
  return {
    type: CLEAR_B2B_ADMIN_LEARN_STATS,
    key,
  };
};

export const fetchUserParticipation = (queryString) => dispatch => (
  APIUtil.fetchUserParticipation(queryString).then(
    payload => dispatch(receiveLearnStats(payload)),
    errors => dispatch(receiveLearnStatErrors(ErrorResponseUtil(errors)))
  )
)

export const fetchUserPerformance = (queryString) => dispatch => (
  APIUtil.fetchUserPerformance(queryString).then(
    payload => dispatch(receiveLearnStats(payload)),
    errors => dispatch(receiveLearnStatErrors(ErrorResponseUtil(errors)))
  )
)
