import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';

import Onboarding from './Onboarding/Onboarding';
import SignUpPage from './Pages/SignUpPage/SignUpPageContainer';
import PasswordPage from './Pages/PasswordPage/PasswordPageContainer';
import SessionExpired from './Pages/SessionExpiredPage/SessionExpired';

const {
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUDIENCE,
} = process.env;

const Root = ({ store }) => {
    if (process.env.REACT_APP_MAINTENANCE_MODE === 'enabled') {
        const maintenanceMessage = process.env.REACT_APP_MAINTENANCE_TEXT || 'This application is temporarily under maintenance.';
        return (
            <div className="maintenance-mode">{ maintenanceMessage }</div>
        );
    }

    if (window.location.hash.startsWith('#/start-learning')) {
        return (
            <Provider store={store}>
                <HashRouter>
                    <React.StrictMode>
                        <Onboarding />
                    </React.StrictMode>
                </HashRouter>
            </Provider>
        )
    }

    if (window.location.hash.startsWith('#/signup')) {
        return (
            <Provider store={store}>
                <HashRouter>
                    <React.StrictMode>
                        <SignUpPage />
                    </React.StrictMode>
                </HashRouter>
            </Provider>
        )
    }

    if (window.location.hash.startsWith('#/forgot-password')) {
        return (
            <Provider store={store}>
                <HashRouter>
                    <React.StrictMode>
                        <PasswordPage />
                    </React.StrictMode>
                </HashRouter>
            </Provider>
        )
    }

    if (window.location.hash.startsWith('#/session-expired')) {
        return (
            <Provider store={store}>
                <HashRouter>
                    <React.StrictMode>
                        <SessionExpired />
                    </React.StrictMode>
                </HashRouter>
            </Provider>
        )
    }

    return (
        <Auth0Provider
            domain={REACT_APP_AUTH0_DOMAIN}
            clientId={REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            audience={REACT_APP_AUDIENCE}
        >
            <Provider store={store}>
                <HashRouter>
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>
                </HashRouter>
            </Provider>
        </Auth0Provider>
    );
};

export default Root;
