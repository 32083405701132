import React from 'react';
import PropTypes from 'prop-types';

import './TextAreaBox.css';

/**
* @function - TextAreaBox - functional
* @{props} - {Object}
 * className -
 * style - style Object
 * onChange - function
 * onKeyPress - function
 * value - string
 * placeholder - string
* @return { JSX Object }
*/

const TextAreaBox = ({ className, style, onChange, onKeyPress, value, placeholder }) => {

  TextAreaBox.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
  };

  TextAreaBox.defaultProps = {
    className: 'TextAreaBox',
    value: "",
    placeholder: "",
  };

  return (
    <textarea
      className={className}
      style={style}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
      placeholder={placeholder}
      type="text" >
    </textarea>
  );
};

export default TextAreaBox;