import React, { Component } from 'react';

import './CreationLessonsDashboard.css';
import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import Modal from '../../Composed/Modal/Modal';
import CreateEditCourse from '../../Forms/CreateEditCourse/CreateEditCourse';
import CreateEditLesson from '../../Forms/CreateEditLesson/CreateEditLesson';
import LessonBoxMini from '../../Presentational/LessonBox/LessonBoxMini';
import ErrorsFlashBox from '../../Composed/ErrorFlashBox/ErrorsFlashBox';
import Loader from '../../Composed/Loader/Loader';

class CreationLessonsDashboard extends Component {
  state = { loading: true };

  componentDidMount() {
    let queryString = new URLSearchParams(this.props.location.search);

    this.props.clearCourseErrors();
    this.props.clearLessonErrors();
    this.props.clearTopics();
    this.props.fetchLessons(this.props.currentCourseId).then(() => {
      this.setState({ loading: false });
      if (queryString.has('nextLes')) {
        let pathHash = window.location.hash.split('?');
        window.location.hash = `${pathHash[0]}${queryString.get('nextLes')}?${pathHash[1]}`;
      }
    });
  }

  handleLessonRender() {
    const { lessons, course, uploadImage } = this.props;
    const lessonList =  course.lessons.map(
      lessonId => {
        return (
          <div
            className="CreationDashboards-LessonList-Item"
            key={lessonId}>
                <div className="PositionButtons-Container">
                    <ButtonBox
                        className="ButtonBox-EditQuestions"
                        text={<i className="fas fa-chevron-up" />}
                        onClick={() => this.props.moveHigher(lessonId)}
                    />
                    <ButtonBox
                        className="ButtonBox-EditQuestions"
                        text={<i className="fas fa-chevron-down" />}
                        onClick={() => this.props.moveLower(lessonId)}
                    />
                </div>
                <LessonBoxMini
                    lesson={lessons[lessonId]}
                    course={course}
                    uploadImage={uploadImage}
                    deleteLesson={this.props.deleteLesson}
                    creation 
                />
          </div>
        );
    })

    return (
      <div
        className="CreationDashboards-LessonList">
        { lessonList }
      </div>
    );
  }

  render() {
    const { lessons, course, courseErrors, courseFlash, lessonErrors, 
            lessonFlash, courseTypes } = this.props;

    if (this.state.loading) {
      return <Loader />
    }

    if (Object.keys(lessons).length < course.lessons.length) {
      return <div className="CreationDashboards"></div>;
    }

    return (
      <div
        className="CreationDashboards">
        <div
          className="CreationDashboards-Menu">
          <TextBox
            text={ course.name }
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="h3"
          />

          <Modal
            title="Edit Course"
            form={
              close =>
                <CreateEditCourse
                  closeModal={close}
                  submitCourse={this.props.editCourse}
                  course={course}
                  courseTypes={courseTypes}
                />
            }>
            {
              open =>
                <div
                  className="CreationDashboards-Menu-Button">
                  <ButtonBox
                    className="CreationDashboards-ButtonBox"
                    text="Edit Course"
                    onClick={open} />
                </div>
            }
          </Modal>
        </div>

        <ErrorsFlashBox
          errors={courseErrors}
          flash={courseFlash} />

        <div
          className="CreationDashboards-DescriptionBox">
          <TextBox
            text="Description: "
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="h5"
          />

          <TextBox
            text={course.description}
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="p"
          />
        </div>

        <div
          className="CreationDashboards-DescriptionBox">
          <TextBox
            text="Credits: "
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="h5"
          />

          <div>
            {
              course.education_credits.map(
                (credit, idx) => 
                  <div key={idx}>
                    <TextBox
                      text={`${credit.amount} ${credit.credit_type} Credits for ${credit.credit_for}`}
                      style={{
                        color: `${COLORS.Blue}`,
                        fontWeight: "600",
                        textDecoration: "none",
                      }}
                      tag="p"
                    />
                  </div>
              )
            }
          </div>
        </div>

        <div
          className="CreationDashboards-Menu">
          <TextBox
            text="Lessons"
            style={{
              color: `${COLORS.Blue}`,
              fontWeight: "600",
              textDecoration: "none",
            }}
            tag="h3"
          />

          <Modal
            title="Add A Lesson"
            form={
              close =>
                <CreateEditLesson
                    closeModal={close}
                    submitLesson={this.props.createLesson}
                    course={course} 
                />
            }>
            {
              open =>
                <div
                  className="CreationDashboards-Menu-Button">
                  <ButtonBox
                    className="CreationDashboards-ButtonBox"
                    text="Add A Lesson"
                    onClick={open} />
                </div>
            }
          </Modal>
        </div>

        <ErrorsFlashBox
          errors={lessonErrors}
          flash={lessonFlash} />

        { this.handleLessonRender() }
      </div>
    );
  }
}

export default CreationLessonsDashboard;
