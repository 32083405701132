import { combineReducers } from 'redux';

import groups from './Admin/GroupsReducer';
import institutions from './Admin/InstitutionsReducer';
import inviteUsers from './Admin/InviteUsersReducer';
import licenses from './Admin/LicenseReducer';
import reports from './Admin/ReportsReducer';
import users from './Admin/UsersReducer';
import userCourses from './Admin/UserCoursesReducer';
import rosterManagement from './Admin/RosterManagementReducer';

export default combineReducers({
    groups,
    institutions,
    inviteUsers,
    licenses,
    reports,
    users,
    userCourses,
    rosterManagement
});
