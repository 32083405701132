import {
  RECEIVE_ROSTER_MANAGEMENT,
  RECEIVE_ROSTER_MANAGEMENT_ERRORS,
  CLEAR_ROSTER_MANAGEMENT
} from '../../Actions/RosterManagementActions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_ROSTER_MANAGEMENT:
      return Object.assign({}, state, action.payload.rosterManagement);
    case CLEAR_ROSTER_MANAGEMENT:
      return {};
    default:
      return state;
  }
}
