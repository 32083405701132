import * as APIUtil from '../Util/RosterManagementApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_ROSTER_MANAGEMENT = 'RECEIVE_ROSTER_MANAGEMENT';
export const RECEIVE_ROSTER_MANAGEMENT_ERRORS = 'RECEIVE_ROSTER_MANAGEMENT_ERRORS';
export const CLEAR_ROSTER_MANAGEMENT = 'CLEAR_ROSTER_MANAGEMENT';

export const receiveRosterManagement = (payload) => ({
  type: RECEIVE_ROSTER_MANAGEMENT,
  payload
});

export const receiveRosterManagementErrors = (errors) => ({
  type: RECEIVE_ROSTER_MANAGEMENT_ERRORS,
  errors
});

export const clearRosterManagement = () => ({
  type: CLEAR_ROSTER_MANAGEMENT
});

export const fetchRosterManagement = (queryString) => dispatch => (
  APIUtil.fetchRosterManagement(queryString).then(
    payload => dispatch(receiveRosterManagement(payload)),
    errors => dispatch(receiveRosterManagementErrors(ErrorResponseUtil(errors)))
  )
)
