import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import ReactTooltip from 'react-tooltip';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import TextBox from '../../Basic/TextBox/TextBox';
import InputBox from '../../Basic/InputBox/InputBox';
import CheckBox from '../../Basic/CheckBox/CheckBox';
import AutoComplete from '../../Composed/AutoComplete/AutoComplete';

import { gaEvent } from '../../../Util/Helper/GoogleUtil';
import { isDateInPast } from '../../../Util/Helper/GenericUtil';
import { proficiencyOptions, acquiredThroughOptions, verifiedByOptions, credentialOptions } from './constants';
import { languagesList } from '../../../Constants/LanguageConstants';

import './CreateEditLingo.scss';

const CreateEditLingo = ({ currentUser, lingo, cardTitle, createLingo, updateLingo, cancelNewLingo, displayProfileWingsModal }) => {
  const [editMode, setEditMode] = useState(lingo ? false : true);
  const [submitting, isSubmitting] = useState(false);
  const [lingoErrors, setLingoErrors] = useState({});
  const [lingoAttrs, setLingoAttrs] = useState(
    lingo ? {
      language:    lingo.language,
      dialect:     lingo.dialect,
      is_native:   lingo.is_native,
      is_dominant: lingo.is_dominant,
      proficiency: (JSON.parse(JSON.stringify(lingo.proficiency)) || {}),
      acquired:    (JSON.parse(JSON.stringify(lingo.acquired)) || {}),
      verified:    (JSON.parse(JSON.stringify(lingo.verified)) || {})
    } : {
      language: '', dialect: '',
      is_native: false, is_dominant: false,
      proficiency: {}, acquired: {}, verified: {}
    }
  );

  const verifyByCredential = lingoAttrs.verified?.by === 'canopy_credential';

  const handleFieldChange = (field, value) => {
    setLingoAttrs({ ...lingoAttrs, [field]: value });
  };

  const handleVerifiedChange = (key, subKey, value) => {
    let newVerify = lingoAttrs.verified;

    if (key === 'by') {
      newVerify['by'] = value;
      if (value === 'canopy_credential') {
        newVerify['other'] = '';
      } else if (value === 'other') {
        newVerify['canopy_credential'] = {};
      } else if (value === 'self_rated') {
        newVerify['canopy_credential'] = {};
        newVerify['other'] = '';
      }
    } else if (key === 'other') {
      newVerify['other'] = value;
    } else if (key === 'canopy_credential'){
      if (!newVerify['canopy_credential'])
        newVerify['canopy_credential'] = {};

      newVerify['canopy_credential'][subKey] = value;
    }

    handleFieldChange('verified', newVerify);
  }

  const handleProficiencyChange = (key, value) =>{
    let newProficiency = lingoAttrs.proficiency;

    newProficiency[key] = value;

    handleFieldChange('proficiency', newProficiency);
  }

  const handleAcquiredChange = (key, value) => {
    let newAcquired = lingoAttrs.acquired;

    if (key === 'through') {
      if (!newAcquired['through'])
        newAcquired['through'] = [];

      const idx = newAcquired['through'].indexOf(value);

      (idx > -1) ? newAcquired['through'].splice(idx, 1) : newAcquired['through'].push(value);
    } else if (key === 'other') {
      newAcquired['other'] = value;
    }

    handleFieldChange('acquired', newAcquired);
  }

  const handleCancel = (e) => {
    e.preventDefault();

    if (lingo) {
      resetState();
      setEditMode(false);
    } else {
      cancelNewLingo();
    }
  }

  const resetState = () => {
    setLingoErrors({});
    setLingoAttrs(lingo ? {
      language:    lingo.language,
      dialect:     lingo.dialect,
      is_native:   lingo.is_native,
      is_dominant: lingo.is_dominant,
      proficiency: (JSON.parse(JSON.stringify(lingo.proficiency)) || {}),
      acquired:    (JSON.parse(JSON.stringify(lingo.acquired)) || {}),
      verified:    (JSON.parse(JSON.stringify(lingo.verified)) || {})
    } : {
      language: '', dialect: '',
      is_native: false, is_dominant: false,
      proficiency: {}, acquired: {}, verified: {}
    });
  }

  const validateForm = () => {
    let errors = {};

    if (!lingoAttrs.language) {
      errors['language'] = 'Please select a language.';
    }

    ['listening', 'speaking', 'reading', 'writing'].forEach(proficiencyMode => {
      if ((lingoAttrs.proficiency?.[proficiencyMode] || '').length === 0) {
        errors[`proficiency_${proficiencyMode}`] = `Self-rated ${proficiencyMode} proficiency is required.`;
      }
    });

    if (currentUser.isTJUStudent && (lingoAttrs.verified?.by || '').length === 0) {
      errors['verified_by'] = 'Please choose appropriate option for the proficiency test in this language.';
    }

    if (lingoAttrs.verified.by === 'other' && (lingoAttrs.verified?.other || '').length === 0) {
      errors['verified_other'] = 'Please specify the source through which you completed the proficiency test.';
    } else if (lingoAttrs.verified.by === 'canopy_credential') {
      let canopyCredentialData = lingoAttrs.verified.canopy_credential || {};

      if ((canopyCredentialData.listening || '').length === 0) {
        errors['verified_credential_listening'] = 'Listening score of Canopy Credential proficiency test is required.'
      }

      if ((canopyCredentialData.speaking || '').length === 0) {
        errors['verified_credential_speaking'] = 'Speaking score of Canopy Credential proficiency test is required.'
      }

      if ((canopyCredentialData.sccc || '').length === 0) {
        errors['verified_credential_sccc'] = 'Social-cultural communicative competancy score of Canopy Credential proficiency test is required.'
      }

      if ((canopyCredentialData.completion_at || '').length === 0) {
        errors['verified_credential_completion_at'] = 'Please specify institution name where Canopy Credential proficiency test is completed.'
      }

      if ((canopyCredentialData.completion_date || '').length === 0) {
        errors['verified_credential_completion_date'] = 'Completion date of Canopy Credential proficiency test is required.'
      } else if (!isDateInPast(canopyCredentialData.completion_date)) {
        errors['verified_credential_completion_date'] = 'Completion date of Canopy Credential proficiency test is invalid.'
      }
    }

    if (currentUser.isTJUStudent && (lingoAttrs.acquired?.through || []).length === 0) {
      errors['acquired_through'] = 'Please select at least one option through which you acquired this language.';
    }

    if ((lingoAttrs.acquired?.through || []).includes('other') && (lingoAttrs.acquired?.other || '').length === 0) {
      errors['acquired_other'] = 'Please specify the source through which you acquired this language.';
    }

    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = validateForm();

    setLingoErrors(errors);

    if (Object.keys(errors).length === 0) {
      isSubmitting(true);

      if (lingo) {
        updateLingo({ id: lingo.id, ...lingoAttrs }).then(res => {
          isSubmitting(false);
          if (res.errors) {
            setLingoErrors({ response: res.errors.lingo })
          } else {
            gaEvent('language_update', { language_name: lingoAttrs.language });
            displayProfileWingsModal();
            setEditMode(false);
          }
        });
      } else {
        createLingo(lingoAttrs).then(res => {
          isSubmitting(false);
          if (res.errors) {
            setLingoErrors({ response: res.errors.lingo })
          } else {
            gaEvent('language_create', { language_name: lingoAttrs.language });
            displayProfileWingsModal();
            cancelNewLingo();
          }
        });
      }
    }
  };

  const renderViewMode = () => {
    return (
      <div className="ViewMode">
        <div className="DashboardFormBox-Content">
          <div className="Verified">
            <p className="Title">Proficiency<span className="Required-Asterisk">*</span></p>
            <div className="DashboardFormBox-Line">
              <div className="DashboardFormBox-Label">
                Self-rated Listening Proficiency
              </div>
              <div className="DashboardFormBox-Label blue">
                {proficiencyOptions[lingo.proficiency.listening || '']}
              </div>
            </div>

            <div className="DashboardFormBox-Line">
              <div className="DashboardFormBox-Label">
                Self-rated Speaking Proficiency
              </div>
              <div className="DashboardFormBox-Label blue">
                {proficiencyOptions[lingo.proficiency.speaking || '']}
              </div>
            </div>

            <div className="DashboardFormBox-Line">
              <div className="DashboardFormBox-Label">
                Self-rated Reading Proficiency
              </div>
              <div className="DashboardFormBox-Label blue">
                {proficiencyOptions[lingo.proficiency.reading || '']}
              </div>
            </div>

            <div className="DashboardFormBox-Line">
              <div className="DashboardFormBox-Label">
                Self-rated Writing Proficiency
              </div>
              <div className="DashboardFormBox-Label blue">
                {proficiencyOptions[lingo.proficiency.writing || '']}
              </div>
            </div>
          </div>

          <div className="DashboardFormBox-Line">
            <div className="DashboardFormBox-Label blue Native">
              {`This is${lingo.is_native ? ' ' : ' not '}my native language`}
            </div>
          </div>

          <div className="DashboardFormBox-Line">
            <div className="DashboardFormBox-Label blue Dominant">
              {`This is${lingo.is_dominant ? ' ' : ' not '}my dominant language`}
            </div>
          </div>
        </div>

        <div className="DashboardFormBox-Content">
          {
            (lingo.verified.by === 'canopy_credential') &&
            <div className="CanopyCredential">
              <p className="Title">CanopyCredential{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}</p>
              <div className="DashboardFormBox-Line">
                <div className="DashboardFormBox-Label">
                  Listening Score:
                </div>
                <div className="DashboardFormBox-Label">
                  {credentialOptions[lingo.verified.canopy_credential?.listening || '']}
                </div>
              </div>

              <div className="DashboardFormBox-Line">
                <div className="DashboardFormBox-Label">
                  Speaking Score:
                </div>
                <div className="DashboardFormBox-Label">
                  {credentialOptions[lingo.verified.canopy_credential?.speaking || '']}
                </div>
              </div>

              <div className="DashboardFormBox-Line">
                <div className="DashboardFormBox-Label">
                  Social-cultural Communicative Competancy Score:
                </div>
                <div className="DashboardFormBox-Label">
                  {credentialOptions[lingo.verified.canopy_credential?.sccc || '']}
                </div>
              </div>


              <div className="DashboardFormBox-Line">
                <div className="DashboardFormBox-Label">
                  Completed at:
                </div>
                <div className="DashboardFormBox-Label">
                  {lingo.verified.canopy_credential?.completion_at || 'Select One'}
                </div>
              </div>

              <div className="DashboardFormBox-Line">
                <div className="DashboardFormBox-Label">
                  Completed on:
                </div>
                <div className="DashboardFormBox-Label">
                  {lingo.verified.canopy_credential?.completion_date ? dateFormat(lingo.verified.canopy_credential.completion_date, 'mm/dd/yyyy') : 'Select One'}
                </div>
              </div>
            </div>
          }

          {
            (lingo.verified.by === 'self_rated') &&
            <div className="DashboardFormBox-Label SelfRated">
              {verifiedByOptions[lingo.verified.by]}{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
            </div>
          }

          {
            (lingo.verified.by === 'other') &&
            <div className="DashboardFormBox-Line Other">
              <div className="DashboardFormBox-Label">
                {lingo.verified.other}{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
              </div>
            </div>
          }

          {
            (!lingo.verified.by) &&
            <div className="DashboardFormBox-Line Nothing">
              <div className="DashboardFormBox-Label">
                No proficiency test
              </div>
            </div>
          }
        </div>

        <div className="DashboardFormBox-Content">
          <div className="DashboardFormBox-Line Acquired">
            <div className="DashboardFormBox-Label">
              I acquired this language by:{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
            </div>
            <div className="DashboardFormBox-Label blue">
              <div>
                <ul className="Acquired-Through-List list-disc">
                  {
                    lingo.acquired.through ? (
                      lingo.acquired.through?.map((entry, idx) => (
                        <li key={idx}>
                          {entry === 'other' ? lingo.acquired?.other : acquiredThroughOptions[entry]}
                        </li>
                      ))
                    ) : (
                      <li>Select One</li>
                    )
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };

  const renderEditMode = () => {
    return (
      <div className="EditMode">
        <form className="LanguageCreateEditLingo">
          <div className="DashboardFormBox-Content">
            {
              (Object.keys(lingoErrors).length > 0) &&
              <ul className="Lingo-Errors list-disc pl-10">
                {
                  Object.entries(lingoErrors).map(([k, v], idx) => (
                    <li key={idx} children={v} />
                  ))
                }
              </ul>
            }

            <div className="DashboardFormBox-Line">
              <div className="DashboardFormBox-Label left">
                Language<span className="Required-Asterisk">*</span>
              </div>
              <div className="DashboardFormBox-Input_container Full">
                <AutoComplete
                  className="Language-Select-Autocomplete"
                  options={Object.entries(languagesList).map(([k, v]) => { return { value: k, label: v } })}
                  defaultValue={{ label: languagesList[lingoAttrs.language], value: lingoAttrs.language }}
                  onChange={(lang) => handleFieldChange('language', lang.value)}
                  isMulti={false}
                />
              </div>
            </div>

            <div className="DashboardFormBox-Line">
              <div className="DashboardFormBox-Label left">
                Any particular dialect?
              </div>
              <div className="DashboardFormBox-Input_container Full">
                <InputBox
                  className="DashboardFormBox-Input"
                  value={lingoAttrs.dialect}
                  placeholder="Optional"
                  onChange={(e) => handleFieldChange('dialect', e.currentTarget.value)}
                />
              </div>
            </div>

            <div className="DashboardFormBox-Line Half">
              <div className="DashboardFormBox-Label left">
                Self-rated Listening Proficiency<span className="Required-Asterisk">*</span>
              </div>
              <div className="DashboardFormBox-Input_container Half">
                <select
                  className="DashboardFormBox-Select"
                  value={lingoAttrs.proficiency.listening}
                  onChange={e => handleProficiencyChange('listening', e.currentTarget.value)}
                >
                  {
                    Object.entries(proficiencyOptions).map(([k, v]) => (
                      <option key={k} value={k} children={v} />
                    ))
                  }
                </select>
              </div>
            </div>

            <div className="DashboardFormBox-Line Half">
              <div className="DashboardFormBox-Label left">
                Self-rated Speaking Proficiency<span className="Required-Asterisk">*</span>
              </div>
              <div className="DashboardFormBox-Input_container Half">
                <select
                  className="DashboardFormBox-Select"
                  value={lingoAttrs.proficiency.speaking}
                  onChange={e => handleProficiencyChange('speaking', e.currentTarget.value)}
                >
                  {
                    Object.entries(proficiencyOptions).map(([k, v]) => (
                      <option key={k} value={k} children={v} />
                    ))
                  }
                </select>
              </div>
            </div>

            <div className="DashboardFormBox-Line Half">
              <div className="DashboardFormBox-Label left">
                Self-rated Reading Proficiency<span className="Required-Asterisk">*</span>
              </div>
              <div className="DashboardFormBox-Input_container Half">
                <select
                  className="DashboardFormBox-Select"
                  value={lingoAttrs.proficiency.reading}
                  onChange={e => handleProficiencyChange('reading', e.currentTarget.value)}
                >
                  {
                    Object.entries(proficiencyOptions).map(([k, v]) => (
                      <option key={k} value={k} children={v} />
                    ))
                  }
                </select>
              </div>
            </div>

            <div className="DashboardFormBox-Line Half">
              <div className="DashboardFormBox-Label left">
                Self-rated Writing Proficiency<span className="Required-Asterisk">*</span>
              </div>
              <div className="DashboardFormBox-Input_container Half">
                <select
                  className="DashboardFormBox-Select"
                  value={lingoAttrs.proficiency.writing}
                  onChange={e => handleProficiencyChange('writing', e.currentTarget.value)}
                >
                  {
                    Object.entries(proficiencyOptions).map(([k, v]) => (
                      <option key={k} value={k} children={v} />
                    ))
                  }
                </select>
              </div>
            </div>

            <div className="DashboardFormBox-Line">
              <div className="DashboardFormBox-Label left">
                <span>
                  Do you consider this to be your
                  <span className="Dashed-Underline" data-tip data-for="native-tool-tip">
                    native
                    <ReactTooltip place="top" type="info" effect="solid" id="native-tool-tip" children="A language that is learned in the early childhood" />
                  </span>
                  or "first" language?
                  <span className="Required-Asterisk">*</span>
                </span>
              </div>
              <div className="DashboardFormBox-Input_container Full">
                {
                  [[true, 'Yes'], [false, 'No']].map(([k, v], idx) => (
                    <div key={idx} className="Native-Radio mb-5">
                      <label>
                        <input
                          type="radio"
                          name="native"
                          value={k}
                          checked={lingoAttrs.is_native === k}
                          onChange={(e) => handleFieldChange('is_native', k)}
                        />
                        {v}
                      </label>
                    </div>
                  ))
                }
              </div>
            </div>

            <div className="DashboardFormBox-Line">
              <div className="DashboardFormBox-Label left">
                <span>
                  Do you consider this to be your
                  <span className="Dashed-Underline" data-tip data-for="dominant-tool-tip">
                    dominant
                    <ReactTooltip place="top" type="info" effect="solid" id="dominant-tool-tip" children="A language that is widely used and has the highest level of proficiency" />
                  </span>
                  language?
                  <span className="Required-Asterisk">*</span>
                </span>
              </div>
              <div className="DashboardFormBox-Input_container Full">
                {
                  [[true, 'Yes'], [false, 'No']].map(([k, v], idx) => (
                    <div key={idx} className="Dominant-Radio mb-5">
                      <label>
                        <input
                          type="radio"
                          name="dominant"
                          value={k}
                          checked={lingoAttrs.is_dominant === k}
                          onChange={(e) => handleFieldChange('is_dominant', k)}
                        />
                        {v}
                      </label>
                    </div>
                  ))
                }
              </div>
            </div>

            <div className="DashboardFormBox-Line">
              <div className="DashboardFormBox-Label left">
                <span>Have you completed a proficiency test in this language?{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}</span>
              </div>
              <div className="DashboardFormBox-Input_container Full">
                {
                  Object.entries(verifiedByOptions).map(([k, v], idx) => (
                    <div key={idx} className="Verified-Radio mb-5">
                      <label>
                        <input
                          type="radio"
                          name="verified"
                          value={k}
                          checked={lingoAttrs.verified?.by === k}
                          onChange={(e) => handleVerifiedChange('by', null, e.currentTarget.value)}
                        />
                        {v}
                      </label>
                    </div>
                  ))
                }

                {
                  (lingoAttrs.verified?.by === 'other') &&
                  <InputBox
                    className="DashboardFormBox-Input"
                    value={lingoAttrs.verified?.other}
                    placeholder="Please specify*"
                    onChange={(e) => handleVerifiedChange('other', null, e.currentTarget.value)}
                  />
                }
              </div>
            </div>

            <div className="DashboardFormBox-Line Half">
              <div className={`DashboardFormBox-Label left ${verifyByCredential ? '' : 'Disabled'}`}>
                <span>CanopyCredential Listening Score<span className="Required-Asterisk">*</span></span>
              </div>
              <div className="DashboardFormBox-Input_container Half">
                <select
                  className="DashboardFormBox-Select"
                  value={lingoAttrs.verified.canopy_credential?.listening || ''}
                  disabled= {!verifyByCredential}
                  onChange={e => handleVerifiedChange('canopy_credential', 'listening', e.currentTarget.value)}
                >
                  {
                    Object.entries(credentialOptions).map(([k, v]) => (
                      <option key={k} value={k} children={v} />
                    ))
                  }
                </select>
              </div>
            </div>

            <div className="DashboardFormBox-Line Half">
              <div className={`DashboardFormBox-Label left ${verifyByCredential ? '' : 'Disabled'}`}>
                <span>CanopyCredential Speaking Score<span className="Required-Asterisk">*</span></span>
              </div>
              <div className="DashboardFormBox-Input_container Half">
                <select
                  className="DashboardFormBox-Select"
                  value={lingoAttrs.verified.canopy_credential?.speaking || ''}
                  disabled= {!verifyByCredential}
                  onChange={e => handleVerifiedChange('canopy_credential', 'speaking', e.currentTarget.value)}
                >
                  {
                    Object.entries(credentialOptions).map(([k, v]) => (
                      <option key={k} value={k} children={v} />
                    ))
                  }
                </select>
              </div>
            </div>

            <div className="DashboardFormBox-Line Half">
              <div className={`DashboardFormBox-Label left ${verifyByCredential ? '' : 'Disabled'}`}>
                <span>CanopyCredential Socio-Cultural Communicative Competancy Score<span className="Required-Asterisk">*</span></span>
              </div>
              <div className="DashboardFormBox-Input_container Half">
                <select
                  className="DashboardFormBox-Select"
                  value={lingoAttrs.verified.canopy_credential?.sccc || ''}
                  disabled= {!verifyByCredential}
                  onChange={e => handleVerifiedChange('canopy_credential', 'sccc', e.currentTarget.value)}
                >
                  {
                    Object.entries(credentialOptions).map(([k, v]) => (
                      <option key={k} value={k} children={v} />
                    ))
                  }
                </select>
              </div>
            </div>

            <div className="DashboardFormBox-Line">
              <div className={`DashboardFormBox-Label left ${verifyByCredential ? '' : 'Disabled'}`}>
                <span>Completed at which institution?<span className="Required-Asterisk">*</span></span>
              </div>
              <div className="DashboardFormBox-Input_container Full">
                <InputBox
                  className="DashboardFormBox-Input"
                  value={lingoAttrs.verified.canopy_credential?.completion_at || ''}
                  disabled= {!verifyByCredential}
                  placeholder="Enter Institution"
                  onChange={(e) => handleVerifiedChange('canopy_credential', 'completion_at', e.currentTarget.value)}
                />
              </div>
            </div>

            <div className="DashboardFormBox-Line">
              <div className={`DashboardFormBox-Label left ${verifyByCredential ? '' : 'Disabled'}`}>
                <span>Date Completed<span className="Required-Asterisk">*</span></span>
              </div>
              <div className="DashboardFormBox-Input_container Full">
                <InputBox
                  className="DashboardFormBox-Input"
                  value={lingoAttrs.verified.canopy_credential?.completion_date || ''}
                  disabled= {!verifyByCredential}
                  onChange={(e) => handleVerifiedChange('canopy_credential', 'completion_date', e.currentTarget.value)}
                  type="date"
                />
              </div>
            </div>

            <div className="DashboardFormBox-Line">
              <div className="DashboardFormBox-Label left" >
                <span>
                  How did you acquire this language?{currentUser.isTJUStudent && <span className="Required-Asterisk">*</span>}
                  <p className="Hint">(Select all that apply)</p>
                </span>
              </div>
              <div className="DashboardFormBox-Input_container LanguageCheckbox Full">
                {
                  Object.entries(acquiredThroughOptions).map(([k, v], idx) => (
                    <div key={idx} className="CheckBox-Container">
                      <label>
                        <CheckBox
                          name={k}
                          value={k}
                          checked={lingoAttrs.acquired?.through ? lingoAttrs.acquired?.through?.includes(k) : false}
                          onChange={(e) => handleAcquiredChange('through', e.currentTarget.value)}
                        />
                        <div className="Acquired-Text">{v}</div>
                      </label>
                    </div>
                  ))
                }

                {
                  lingoAttrs.acquired?.through?.includes('other') &&
                  <InputBox
                    className="DashboardFormBox-Input"
                    value={lingoAttrs.acquired?.other}
                    placeholder="Please specify*"
                    onChange={(e) => handleAcquiredChange('other', e.currentTarget.value)}
                  />
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  };

  return (
    <div className="DashboardFormBox LingoCard">
      <div className="DashboardFormBox-Title_container">
        <div className="DashboardFormBox-Title">
          <div className="Title">
            <TextBox
              text={cardTitle}
            />
          </div>
            {
              !editMode && (
                <div className="Dialect">
                  {lingo.dialect || 'No dialect'}
                </div>
              )
            }
        </div>

        {
          editMode ? (
            <div className="DashboardFormBox-EditModeBtns">
              <ButtonBox
                className="DashboardFormBox-Button_cancel"
                text="Cancel"
                onClick={(e) => handleCancel(e)}
              />

              <ButtonBox
                className="DashboardFormBox-Button_save"
                text="Save"
                disabled={submitting}
                onClick={(e) => handleSubmit(e)}
              />
            </div>
          ) : (
            <div className="DashboardFormBox-ViewModeBtns">
              <ButtonBox
                className="DashboardFormBox-Button_edit"
                text="Edit"
                onClick={() => setEditMode(true)}
              />
            </div>
          )
        }
      </div>

      <div className="DashboardFormBox-Content_container">
        {editMode ? renderEditMode() : renderViewMode()}
      </div>

      {
        editMode &&
        <div className="DashboardFormBox-Footer_container">
          <div className="DashboardFormBox-EditModeFooterBtns">
            <div className="Bottom-Buttons">
              <ButtonBox
                className="DashboardFormBox-Button_cancel"
                text="Cancel"
                onClick={(e) => handleCancel(e)}
              />

              <ButtonBox
                className="DashboardFormBox-Button_save"
                text="Save"
                disabled={submitting}
                onClick={(e) => handleSubmit(e)}
              />
            </div>
          </div>
        </div>
      }
    </div>
  );
}

CreateEditLingo.propTypes = {
  currentUser: PropTypes.object.isRequired,
  lingo: PropTypes.object,
  cardTitle: PropTypes.string.isRequired,
  createLingo: PropTypes.func,
  updateLingo: PropTypes.func,
  cancelNewLingo: PropTypes.func,
  displayProfileWingsModal: PropTypes.func.isRequired,
};

export default CreateEditLingo;
