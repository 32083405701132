import React, { useState, useEffect } from 'react';
import { ScaleLoader } from 'react-spinners';
import { Link, Switch } from 'react-router-dom';
import { COLORS } from '../../../../../Style/Style';
import TextBox from '../../../../Basic/TextBox/TextBox';
import InputBox from '../../../../Basic/InputBox/InputBox';

const SearchUsers = ({ users, fetchUsers }) => {
    const [filter, setFilter] = useState('');

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const renderLoader = () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ScaleLoader
                color={COLORS.greyBlue}
                height={50}
                width={50}
            />
        </div>
    );

    const renderErrorMessage = () => (
        <div>
            Something went wrong. Please refresh and try again.
        </div>
    );

    const getFilteredUsers = () => (
        users.data.filter(user => (
            user.email.toLowerCase().includes(filter.toLowerCase()) ||
            user.first_name.toLowerCase().includes(filter.toLowerCase()) ||
            user.last_name.toLowerCase().includes(filter.toLowerCase())
        ))
    );

    const renderSearchUsers = () => (
        <>
            <div className="B2BAdminDashboard-GroupList__container">
                <TextBox
                    text="Search for users by entering relevant information below"
                    style={{
                        margin: '20px 0',
                        color: COLORS.Blue,
                    }}
                />
            </div>

            <div className="B2BAdminDashboard-GroupList__container">
                <div style={{
                    backgroundColor: 'white',
                    padding: 20,
                    border: '1px solid #d6dbdf',
                    marginBottom: 20,
                }}
                >
                    <div className="SearchBox__label">
                        <span>SEARCH FILTERS</span>
                        <InputBox
                            value={filter}
                            placeholder="First Name, Last Name, or Email"
                            onChange={e => setFilter(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="B2BAdminDashboard-GroupList__container">
                <div style={{
                    backgroundColor: 'white',
                    padding: 20,
                    border: '1px solid #d6dbdf',
                }}
                >
                    <table style={{
                        width: '100%',
                    }}>
                        <thead>
                            <tr style={{ border: 'solid 1px #cccccc' }}>
                                <td style={{ padding: 15, border: 'none' }}>First Name</td>
                                <td style={{ padding: 15, border: 'none' }}>Last Name</td>
                                <td style={{ padding: 15, border: 'none' }}>Email</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getFilteredUsers().map(user => (
                                    <tr style={{ border: 'solid 1px #cccccc' }}>
                                        <td style={{ padding: 15, border: 'none' }}>{user.first_name}</td>
                                        <td style={{ padding: 15, border: 'none' }}>{user.last_name}</td>
                                        <td style={{ padding: 15, border: 'none' }}>{user.email}</td>
                                        <td style={{ padding: 15, border: 'none' }}>
                                            <Link to={`/account/search/${user.id}`}>
                                                Details
                                            </Link>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );

    const decideWhichComponentsToRender = () => {
        if (users.isLoading) {
            return renderLoader();
        }
        if (users.isError) {
            return renderErrorMessage();
        }
        return renderSearchUsers();
    };

    return (
        <>
            {decideWhichComponentsToRender()}
        </>
    );
};


export default SearchUsers;
