import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import TutorialContainer from '../../Presentational/Tutorial/TutorialContainer';
import StatStar from '../../Composed/StatStar/StatStar';

import { courseSeriesList } from '../../../Constants/CourseConstants';
import { hoursMinutes } from '../../../Util/Helper/GenericUtil';

const AdminHome = ({ currentUser }) => {
    const [licenseTab, setLicenseTab] = useState(Object.values(currentUser.perLicenseSeats).reverse()[0]?.id);

    return (
        <div className="AdminHome">
            <TutorialContainer />

            <Link to="/account/search">
                <div className="UserDashboard-Learn-Links Tour-Step-Home-Users-Menu">
                    <i className="fas fa-search icon-purchase-success" />

                    <TextBox
                        className="Heading"
                        text="ADD/SEARCH USERS"
                        tag="p"
                    />

                    <TextBox
                        className="SubHeading"
                        text="Add new users, and view details of individual users"
                        tag="h5"
                    />
                </div>
            </Link>

            <Link to="/account/progress">
                <div className="UserDashboard-Learn-Links Tour-Step-Home-Progress-Menu">
                    <i className="fas fa-pencil-alt icon-purchase-success" />

                    <TextBox
                        className="Heading"
                        text="COURSE PROGRESS"
                        tag="p"
                    />

                    <TextBox
                        className="SubHeading"
                        text="Check progress and performance of users and groups"
                        tag="h5"
                    />
                </div>
            </Link>

            <Link to="/account/groups">
                <div className="UserDashboard-Learn-Links Tour-Step-Home-Groups-Menu">
                    <i className="fas fa-user-friends icon-purchase-success" />

                    <TextBox
                        className="Heading"
                        text="MANAGE GROUPS"
                        tag="p"
                    />

                    <TextBox
                        className="SubHeading"
                        text="Organize and keep track of your users with groups"
                        tag="h5"
                    />
                </div>
            </Link>

            <div className="Basic-Stats">
                <div className="Stats">
                    <div className="Stats-Container">
                        <div className="Head">
                            <div className="Title">Time Spent</div>
                            <div className="Product">Canopy Learn/Compliance</div>
                        </div>

                        <div className="Body">
                            <div className="Description">Total time spent on all courses by users in the past 365 days.</div>
                        </div>

                        <div className="Foot">
                            <StatStar
                                stat="Time (HH:MM)"
                                value={hoursMinutes(currentUser.grossTimeSpent.seconds)}
                            />
                        </div>
                    </div>

                    <div className="Stats-Container">
                        <div className="Head">
                            <div className="Title">Active Users</div>
                            <div className="Product">Canopy Learn/Compliance/Credential</div>
                        </div>

                        <div className="Body">
                            <div className="Description">Percentage of users that were active recently.</div>
                        </div>

                        <div className="Foot">
                            <StatStar
                                stat="Last 7 Days"
                                value={`${currentUser.grossActiveUsers.ratio_in_week}%`}
                            />
                            <StatStar
                                stat="Last 30 Days"
                                value={`${currentUser.grossActiveUsers.ratio_in_month}%`}
                            />
                        </div>
                    </div>

                    <div className="Stats-Container">
                        <div className="Head">
                            <div className="Title">Profiles Completed</div>
                            <div className="Product">Canopy Learn/Compliance</div>
                        </div>

                        <div className="Body">
                            <div className="Description">Users who have completed their profiles.</div>
                        </div>

                        <div className="Foot">
                            <StatStar
                                stat="Number of Users"
                                value={currentUser.grossProfilesCompleted.number_of_users}
                            />
                            <StatStar
                                stat="Percentage of Users"
                                value={`${currentUser.grossProfilesCompleted.percent_of_users}%`}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {
                Object.keys(currentUser.perLicenseSeats).length > 0 &&
                <div className="License-Stats">
                    <div className="Tabs">
                        {
                            Object.values(currentUser.perLicenseSeats).reverse().map((license, idx) => (
                                <div
                                    className={`Tab ${licenseTab === license.id ? 'Active' : ''}`}
                                    onClick={() => setLicenseTab(license.id)}
                                >
                                    {license.access_code}
                                </div>
                            ))
                        }
                    </div>

                    <div className="Stats">
                        <div className="Stats-Container">
                            <div className="Head">
                                <div className="Title">Seats Occupied</div>
                                <div className="Product">Canopy Learn/Compliance</div>
                            </div>
                            <div className="Body">
                                <div className="Description">Number of seats occupied out of total allocated seats.</div>
                            </div>
                            <div className="Foot">
                                <StatStar
                                    stat={currentUser.perLicenseSeats?.[licenseTab]?.['access_code']}
                                    value={currentUser.perLicenseSeats?.[licenseTab]?.['num_occupied']}
                                    info={`total: ${currentUser.perLicenseSeats?.[licenseTab]?.['num_seats'] || 'No limit'}`}
                                />
                            </div>
                        </div>

                        <div className="Stats-Container Small-Fonts">
                            <div className="Head">
                                <div className="Title">Started Program</div>
                                <div className="Product">Canopy Learn/Compliance</div>
                            </div>
                            <div className="Body">
                                <div className="Description">Number of users started learning levels in a particular program.</div>
                            </div>
                            <div className="Foot">
                                {
                                    currentUser.perLicenseStartedLearning[licenseTab] ? (
                                        Object.entries(currentUser.perLicenseStartedLearning[licenseTab]).map(([series, data], idx) => (
                                            <StatStar
                                                stat={courseSeriesList[series]}
                                                value={Object.entries(data).map(([level, num]) => <div>{`Level ${level}: ${num}`}</div>)}
                                            />
                                        ))
                                    ) : (
                                        <div className="Empty">No user has started a program yet!</div>
                                    )
                                }
                            </div>
                        </div>

                        <div className="Stats-Container">
                            <div className="Head">
                                <div className="Title">PreCourse Assessment Attempted</div>
                                <div className="Product">Canopy Learn</div>
                            </div>
                            <div className="Body">
                                <div className="Description">Number of users attempted pre-course assessment.</div>
                            </div>
                            <div className="Foot">
                                {
                                    currentUser.perLicensePreCourseAttempts[licenseTab] ? (
                                        Object.entries(currentUser.perLicensePreCourseAttempts[licenseTab]).map(([series, data], idx) => (
                                            <StatStar
                                                stat={courseSeriesList[series]}
                                                value={data.number_of_users}
                                                info={`${data.percent_of_users}% of users`}
                                            />
                                        ))
                                    ) : (
                                        <div className="Empty">No user has attempted pre-course assessment yet!</div>
                                    )
                                }
                            </div>
                        </div>

                        <div className="Stats-Container">
                            <div className="Head">
                                <div className="Title">Certificates Generated</div>
                                <div className="Product">Canopy Learn/Compliance</div>
                            </div>
                            <div className="Body">
                                <div className="Description">Number of certificates generated in the past 30 days.</div>
                            </div>
                            <div className="Foot">
                                {
                                    currentUser.perLicenseCompletedLevels[licenseTab] ? (
                                        Object.entries(currentUser.perLicenseCompletedLevels[licenseTab]).map(([series, data], idx) => (
                                            <StatStar
                                                stat={courseSeriesList[series]}
                                                value={Object.values(data).reduce((total, num) => total + num)}
                                            />
                                        ))
                                    ) : (
                                        <div className="Empty">No user has generated a certificate in the past 30 days!</div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default AdminHome;
