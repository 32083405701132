import { connect } from 'react-redux';
import QuizPage from './QuizPage';
import {
    fetchQuiz,
    recordQuizAnswer,
    recordQuizState,
    submitQuiz,
    clearQuizProgresses,
    clearQuizStates,
} from '../../../Actions/QuizActions';
import { clearSectionIndex, receiveSectionIndex } from '../../../Actions/NavigationActions';
import { createBookmark, deleteBookmark } from '../../../Actions/BookmarkActions';

const mapStateToProps = (state, { match }) => ({
    course: state.entities.courses[match.params.courseId],
    courseProgresses: state.statistics.courseProgresses,
    lesson: state.entities.lessons[match.params.lessonId],
    lessons: state.entities.lessons,
    courseId: match.params.courseId,
    lessonId: match.params.lessonId,
    quizzes: state.entities.quizzes,
    quizProgress: state.statistics.quizProgresses[match.params.lessonId],
    quizResults: state.statistics.quizResults,
    bookmarks: state.statistics.bookmarks,
    quizStates: state.statistics.quizStates,
    currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
    fetchQuiz: (lessonId) => dispatch(fetchQuiz(lessonId)),
    recordQuizAnswer: (answer) => dispatch(recordQuizAnswer(answer)),
    recordQuizState: (state) => dispatch(recordQuizState(state)),
    submitQuiz: (data) => dispatch(submitQuiz(data)),
    clearQuizProgresses: () => dispatch(clearQuizProgresses()),
    clearQuizStates: () => dispatch(clearQuizStates()),
    clearSectionIndex: () => dispatch(clearSectionIndex()),
    receiveSectionIndex: index => dispatch(receiveSectionIndex(index)),
    createBookmark: (bookmark) => dispatch(createBookmark(bookmark)),
    deleteBookmark: (bookmark) => dispatch(deleteBookmark(bookmark))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizPage);
