import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactNumberFormat from 'react-number-format';
import { MoonLoader } from 'react-spinners';

import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

import { microsoftAdScript } from '../../../Util/Helper/MicrosoftUtil';
import { clearCoursePackages, fetchProducts } from '../../../Actions/CoursePackageActions';
import { fetchInventories } from '../../../Actions/InventoryActions';
import { courseLanguageList } from '../../../Constants/CourseConstants';

import './Packages.scss';

const mapStateToProps = (state) => ({
  coursePackages: state.entities.coursePackages,
  inventories: state.entities.inventories
});

const mapDispatchToProps = (dispatch) => ({
  clearCoursePackages: () => dispatch(clearCoursePackages()),
  fetchProducts: () => dispatch(fetchProducts()),
  fetchInventories: () => dispatch(fetchInventories())
});

const {
  REACT_APP_ONE_DOMAIN
} = process.env;

const Packages = ({
  coursePackages, clearCoursePackages, fetchProducts,
  inventories, fetchInventories
}) => {
  const [loadLearn, setLoadLearn] = useState(true);
  const [loadCredential, setLoadCredential] = useState(true);
  const [language, setLanguage] = useState('SP');

  useEffect(() => {
    microsoftAdScript();
  }, []);

  useEffect(() => {
    clearCoursePackages();
    fetchProducts().then(res => setLoadLearn(false));
  }, [clearCoursePackages, fetchProducts]);

  useEffect(() => {
    fetchInventories().then(res => setLoadCredential(false));
  }, [fetchInventories]);

  const handleLearnSwitch = (e) => {
    setLanguage(e.currentTarget.checked ? 'EN' : 'SP')
  }

  const renderLoader = () => (
    <div className="Products-Loader">
      <MoonLoader color="#000" />
    </div>
  )

  const renderLearnPackages = () => (
    Object.values(coursePackages).sort((a, b) => a.sort_order > b.sort_order ? 1 : -1).map((coursePackage, idx) => (
      coursePackage.course_languages?.includes(language) &&
      <div className="CoursePackageBox" key={`learn-package-${idx}`}>
        <div className="CoursePackageBox-DataBox">
          <div className="CoursePackageBox-Series">
            <TextBox
              text={`Medical ${courseLanguageList[language]}`}
              tag="h5"
            />
          </div>

          <div className="CoursePackageBox-Name">
            <TextBox
              text={coursePackage.name}
              tag="h5"
            />
          </div>

          <div className="CoursePackageBox-Price">
            <TextBox
              text={`${coursePackage.length} Month Subscription`}
            />

            <ReactNumberFormat
              displayType="text"
              value={coursePackage.price / 100}
              prefix="$"
              decimalScale={2}
              allowNegative={false}
              thousandSeparator
            />
          </div>

          <div className="CoursePackageBox-Description">
            <TextBox
              text={coursePackage.description}
            />
          </div>
        </div>

        <div className="CoursePackageBox-ButtonBox">
          <Link to={`/start-learning/package/${coursePackage.id}`}>
            <ButtonBox
              className="ButtonBox-CoursePackageBox"
              text="SELECT"
            />
          </Link>
        </div>

        {
          coursePackage.tag && (
            <div className="CoursePackageTag">
              <p>{coursePackage.tag}</p>
            </div>
          )
        }
      </div>
    ))
  )

  const renderCredentialInventories = () => (
    Object.values(inventories).map((inventory, idx) => (
      <div className={`CoursePackageBox ${idx === 0 ? 'w-628' : ''}`} key={`credential-inventory-${idx}`}>
        <div className="CoursePackageBox-DataBox">
          <div className="CoursePackageBox-Series">
            <TextBox
              text={inventory.banner}
              tag="h5"
            />
          </div>

          <div className="CoursePackageBox-Name">
            <h5>{inventory.name}</h5>
            <p>{inventory.variant}</p>
          </div>

          <div className="CoursePackageBox-Price">
            <p>{inventory.description}</p>
            <span>{inventory.price === 0 ? 'FREE' : `$ ${(inventory.price / 100)}`}</span>
          </div>

          <div className="CoursePackageBox-Benefits">
            {
              inventory.benefits.map(benefit => <p key={benefit}>{benefit}</p>)
            }
          </div>

          <div className="CoursePackageBox-Certificates-label">
            <ul className="list-disc">
              <li>
                <TextBox
                  text="Includes Certificate of Bilingual Proficiency"
                />
                <p className="InfoText">*{inventory.disclaimer}</p>
              </li>
            </ul>
          </div>
        </div>

        <div className="CoursePackageBox-ButtonBox">
          <ButtonBox
            className="ButtonBox-CoursePackageBox"
            text="SELECT"
            onClick={(e) => { window.location = `${REACT_APP_ONE_DOMAIN}/checkout/${inventory.id}` }}
          />
        </div>
      </div>
    ))
  )

  return (
    <>
      <div className="UserDashboardCoursePackages container Learn mt-100">
        <div className="CoursePackage-Title-Container">
          <TextBox
            className="CoursePackage-Title"
            text={`Improve your Medical ${courseLanguageList[language]} with Canopy`}
            tag="h1"
          />
        </div>

        <div className="Canopy-Type-Container">
          <div className="Canopy-Type">
            <TextBox
              className="Canopy-Type-Name"
              text="CANOPY LEARN"
            />
            <TextBox
              className="Canopy-Type-Desc"
              text=""
            />
          </div>

          <div className="Canopy-Switch">
            <div className="switch-button">
              <input className="switch-button-checkbox" type="checkbox" onClick={(e) => handleLearnSwitch(e)}></input>
              <label className="switch-button-label"><span className="switch-button-label-span">SPANISH</span></label>
            </div>
          </div>
        </div>

        <div className="UserDashboardCoursePackages-Packages__container">
          {
            loadLearn ? renderLoader() : renderLearnPackages()
          }
        </div>
      </div>

      <div className="UserDashboardCoursePackages container Credential mt-0">
        <div className="Canopy-Type-Container">
          <div className="Canopy-Type">
            <TextBox
              className="Canopy-Type-Name"
              text="CANOPY Credential"
            />
            <TextBox
              className="Canopy-Type-Desc"
              text=""
            />
          </div>

          <div className="Canopy-Switch">
          </div>
        </div>

        <div className="UserDashboardCoursePackages-Packages__container">
          {
            loadCredential ? renderLoader() : renderCredentialInventories()
          }
        </div>
      </div>
    </>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
