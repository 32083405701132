export const proficiencyCodeToDesc = {
  'A1':  { 'canopyLevel': 'Basic Level 1',          'isQbsScore': false, 'points': null, 'isScoreable': false },
  'A2':  { 'canopyLevel': 'Basic Level 2',          'isQbsScore': false, 'points': 2,    'isScoreable': true  },
  'LB1': { 'canopyLevel': 'Emergent Level 1',       'isQbsScore': false, 'points': 4,    'isScoreable': true  },
  'HB1': { 'canopyLevel': 'Emergent Level 2',       'isQbsScore': false, 'points': 6,    'isScoreable': true  },
  'LB2': { 'canopyLevel': 'Conversational Level 1', 'isQbsScore': false, 'points': 8,    'isScoreable': true  },
  'HB2': { 'canopyLevel': 'Conversational Level 2', 'isQbsScore': true,  'points': 10,   'isScoreable': true  },
  'C1':  { 'canopyLevel': 'Advanced Level 1',       'isQbsScore': true,  'points': 12,   'isScoreable': true  },
  'C2':  { 'canopyLevel': 'Advanced Level 2',       'isQbsScore': true,  'points': null, 'isScoreable': false },
}

export const applicableProficiencyCodes = Object.entries(proficiencyCodeToDesc).map(([k, v]) => v['isScoreable'] ? k : null).filter(k => k);
export const qbsThresholdCodes = Object.entries(proficiencyCodeToDesc).map(([k, v]) => v['isQbsScore'] ? k : null).filter(k => k);

export const proficiencyCodeToRange = {
  "baseListening": {
    "A1":  { "max": 16,  "min": 0   },
    "A2":  { "max": 32,  "min": 17  },
    "LB1": { "max": 48,  "min": 33  },
    "HB1": { "max": 65,  "min": 49  },
    "LB2": { "max": 98,  "min": 66  },
    "HB2": { "max": 131, "min": 99  },
    "C1":  { "max": 176, "min": 132 },
  },
  "baseSpeaking": {
    "A1":  { "max": 13,  "min": 0   },
    "A2":  { "max": 26,  "min": 14  },
    "LB1": { "max": 39,  "min": 27  },
    "HB1": { "max": 53,  "min": 40  },
    "LB2": { "max": 80,  "min": 54  },
    "HB2": { "max": 107, "min": 81  },
    "C1":  { "max": 144, "min": 108 },
  },
  "targetListening": {
    "A1":  { "max": 14,  "min": 0   },
    "A2":  { "max": 29,  "min": 15  },
    "LB1": { "max": 44,  "min": 30  },
    "HB1": { "max": 59,  "min": 45  },
    "LB2": { "max": 89,  "min": 60  },
    "HB2": { "max": 119, "min": 90  },
    "C1":  { "max": 160, "min": 120 },
  },
  "targetSpeaking": {
    "A1":  { "max": 13,  "min": 0   },
    "A2":  { "max": 26,  "min": 14  },
    "LB1": { "max": 39,  "min": 27  },
    "HB1": { "max": 53,  "min": 40  },
    "LB2": { "max": 80,  "min": 54  },
    "HB2": { "max": 107, "min": 81  },
    "C1":  { "max": 144, "min": 108 },
  }
}

export const competanceCodeToDesc = {
  'sccc': {
    '1': { 'canopyLevel': 'Not Yet Competent',    'isQbsScore': false, 'isScoreable': true },
    '2': { 'canopyLevel': 'Somewhat Competent',   'isQbsScore': false, 'isScoreable': true },
    '3': { 'canopyLevel': 'Reasonably Competent', 'isQbsScore': true,  'isScoreable': true },
    '4': { 'canopyLevel': 'Highly Competent',     'isQbsScore': true,  'isScoreable': true },
  }
}

export const examStatusesList = {
  'Registered For Exam'      : { 'waiting_for_self_assessments': 'Waiting For Self Assessment', 'assigned': 'Waiting For Exam', 'active': 'Active' },
  'Completed Exam'           : { 'done': 'Waiting For Grades', 'rated': 'Received Grades' },
  'Qualified Bilingual Staff': { 'achieved_qbs': 'Achieved QBS' }
}

export const statusToPercentage = {
  'assigned':   '33',
  'done':       '66',
  'rated':      '100',
  'terminated': '0',
}
