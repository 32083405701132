import React from 'react';
import ReactNumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './CoursePackageBox.css';
import TextBox from '../../Basic/TextBox/TextBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import { COLORS } from '../../../Style/Style';
import { payablePrice } from '../../../Util/Helper/StripeUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';
import { courseLanguageList } from '../../../Constants/CourseConstants';

const CoursePackageBox = ({
    coursePackage,
    creation,
    currentUser,
    promoData,
    large,
    premiumPackage,
}) => {
    if (large) {
        return (
            <div className="CoursePackageBox__large">
                <div className="CoursePackageBox-Title">
                    <TextBox
                        text="CONGRATULATIONS ON CHOOSING"
                        style={{
                            color: 'white',
                            fontSize: '14px',
                            letterSpacing: '1px',
                            fontWeight: 'bold'
                        }}
                    />
                </div>

                <div className="CoursePackageBox-Name-large">
                    <TextBox
                        text={coursePackage.name}
                        style={{
                            color: 'white',
                            margin: '20px 0',
                            fontSize: '28px',
                            letterSpacing: '2px',
                            textTransform: 'uppercase'
                        }}
                        tag="h5"
                    />
                </div>

                <div className={promoData ? 'CoursePackageBox-Price-large strike-through' : 'CoursePackageBox-Price-large'}>
                    <ReactNumberFormat
                        displayType="text"
                        value={coursePackage.price / 100}
                        prefix="$"
                        decimalScale={2}
                        allowNegative={false}
                        thousandSeparator
                        style={{
                            color: 'white',
                            fontSize: '42px',
                            fontWeight: 'bold'
                        }}
                    />
                </div>

                {
                    promoData?.coupon
                    && (
                        <ReactNumberFormat
                            displayType="text"
                            value= {payablePrice(coursePackage, promoData)}
                            prefix="$"
                            decimalScale={2}
                            allowNegative={false}
                            thousandSeparator
                            style={{
                                color: 'white',
                                margin: '10px 0',
                                fontSize: '32px',
                            }}
                        />
                    )
                }

                <div className="CoursePackageBox-Certificates-label">
                    <TextBox
                        text="Includes Certificate of Completion"
                        style={{
                            color: 'white',
                            fontSize: '14px',
                            margin: '40px 0',
                        }}
                    />
                </div>

                {
                    false && (
                        <div className="premiumPackage">
                            <p>
                                Select <u>Preimum Package</u> if you would like to take advantage of the following:
                            </p>
                            <ul>
                                <li>
                                  Full Access to Canopy Learn content ({premiumPackage.name})
                                </li>
                                <li>
                                  Extended {premiumPackage.length} Month Access to course content
                                </li>
                            </ul>
                        </div>
                    )
                }
            </div>
        );
    }

    return (
        <div className="CoursePackageBox">
            <div className="CoursePackageBox-DataBox">
                <div className="CoursePackageBox-Series">
                    <TextBox
                        text={`Medical ${courseLanguageList[coursePackage.course_languages[0] || 'SP']}`}
                        tag="h5"
                    />
                </div>

                <div className="CoursePackageBox-Name">
                    <TextBox
                        text={coursePackage.name}
                        tag="h5"
                    />
                </div>

                <div className="CoursePackageBox-Price">
                    <TextBox
                        text={`${coursePackage.length} Month Subscription`}
                    />

                    <ReactNumberFormat
                        displayType="text"
                        value={coursePackage.price / 100}
                        prefix="$"
                        decimalScale={2}
                        allowNegative={false}
                        thousandSeparator
                    />
                </div>

                <div className="CoursePackageBox-Description">
                    <TextBox
                        text={coursePackage.description}
                    />
                </div>
            </div>

            <div className="CoursePackageBox-ButtonBox">
                {
                    creation
                        ? (
                            <Link to={`/account/course_packages/${coursePackage.id}`}>
                                <ButtonBox
                                    className="ButtonBox-CoursePackageBox"
                                    text="Edit Package"
                                />
                            </Link>
                        )
                        : (
                            <Link to={
                                currentUser
                                    ? `/account/learn/course_packages/${coursePackage.id}`
                                    : '/signup'
                            }
                            onClick={() => currentUser && gaEvent('course_package_select', { course_package_id: coursePackage.id })}
                            >
                                <ButtonBox
                                    className="ButtonBox-CoursePackageBox"
                                    text="SELECT"
                                />
                            </Link>
                        )
                }
            </div>

            {
                coursePackage.tag && (
                    <div className="CoursePackageTag">
                      <p>{coursePackage.tag}</p>
                    </div>
                )
            }
        </div>
    );
};

CoursePackageBox.propTypes = {
    coursePackage: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        price: PropTypes.number,
        series: PropTypes.string,
    }).isRequired,
    creation: PropTypes.bool,
    currentUser: PropTypes.shape({}),
    large: PropTypes.bool,
    promoData: PropTypes.shape({
        coupon: PropTypes.object,
    }),
};

CoursePackageBox.defaultProps = {
    creation: false,
    large: false,
    promoData: null,
};

export default CoursePackageBox;
