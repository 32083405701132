import React from 'react';
import { Link } from 'react-router-dom';

export const titles = {
  welcome_when_institution_admin_signs_up: <div className="Title">Action Needed: Baseline Checklist & Survey</div>,
  guide_to_invite_users_after_institution_admin_signs_up: <div className="Title">You haven't added any users!</div>,
  remind_to_invite_users_if_not_after_institution_admin_signs_up: <div className="Title">Urgent: You haven't added any learners to your account!</div>,
  notify_institution_admin_when_few_license_seats_left: <div className="Title">Canopy License Update - Limited Seats Remaining</div>,
  notify_institution_admin_when_license_seats_are_full: <div className="Title">Canopy License Update - No Seats Remaining</div>,
  introduction_to_profile_when_user_signs_up: <div className="Title">Action Required: Complete Your Canopy Profile</div>,
  introduction_to_profile_when_user_purchases_a_level: <div className="Title">Action Required: Complete Your Canopy Profile</div>,
  introduce_streaks_if_has_courses_when_user_completes_profile: <div className="Title">Keeping You Motivated and Accountable</div>,
  level_1_overview_if_accessible_when_user_completes_profile: <div className="Title">Level 1: What to Expect</div>,
  level_2_overview_if_accessible_when_user_completes_profile: <div className="Title">Level 2: What to Expect</div>,
  level_3_overview_if_accessible_when_user_completes_profile: <div className="Title">Level 3: What to Expect</div>,
  guide_about_certificate_when_completes_a_level: <div className="Title">Congratulations!</div>,
  level_2_overview_if_accessible_when_user_completes_level_1: <div className="Title">Level 2: What to Expect</div>,
  level_3_overview_if_accessible_when_user_completes_level_2: <div className="Title">Level 3: What to Expect</div>,
  remind_to_adjust_settings_before_one_month_of_auto_renewal: <div className="Title">Auto-Renew (30 days reminder)</div>,
  remind_to_adjust_settings_before_three_days_of_auto_renewal: <div className="Title">Auto-Renew (3 days reminder)</div>,
};

export const welcome_when_institution_admin_signs_up = (
  <div className="Body">
    <p className="Text">Your role as an administrator is pivotal to ensuring a successful partnership, and we value your insights and contributions.</p>
    <p className="Text">To kick things off, we invite you to participate in our <strong>Canopy Baseline Checklist & Survey</strong>. This survey is designed to help us gain a deep understanding of your organization's top goals and objectives as a Canopy client.</p>
    <a className="Link" href="https://share.hsforms.com/1yX8Se8riSdCmvPYPz3nCxA3jlta" target="_blank">Complete Survey</a>
  </div>
);

export const guide_to_invite_users_after_institution_admin_signs_up = (
  <div className="Body">
    <p className="Text">
      Click&nbsp;
      <Link className="Link" to="/account/invite_users/new">here</Link>&nbsp;
      to Add Users now.
    </p>
    <p className="Text">
      Need assistance? Click&nbsp;
      <a className="Link" href="https://uploads-ssl.webflow.com/63cbcd54f604a371d478ce14/64b851751a0c8382ea37ab94_Updated%20-%20Canopy%20Admin%20Dashboard%20Guide.pdf" target="_blank">here</a>&nbsp;
      to access our "<strong>Canopy Admin Set Up Guide</strong>".
    </p>
  </div>
);

export const remind_to_invite_users_if_not_after_institution_admin_signs_up = (
  <div className="Body">
    <p className="Text">You are set up, but you haven't added any learners to your account.</p>
    <p className="Text">
      Click&nbsp;
      <Link className="Link" to="/account/invite_users/new">here</Link>&nbsp;
      to add users, quickly and simply.
    </p>
    <p className="Text">
      Need help? Click&nbsp;
      <a className="Link" href="https://uploads-ssl.webflow.com/63cbcd54f604a371d478ce14/64b851751a0c8382ea37ab94_Updated%20-%20Canopy%20Admin%20Dashboard%20Guide.pdf" target="_blank">here</a>&nbsp;
      to access our "<strong>Canopy Admin Set Up Guide</strong>".
    </p>
  </div>
);

export const notify_institution_admin_when_few_license_seats_left = (
  <div className="Body">
    <p className="Text">Your Canopy institutional license only has less than 5 seats remaining. Please review this against your expected user count and contact your Canopy sales representative or <a href="mailto:info@canopyapps.com">info@canopyapps.com</a> if you'd like to purchase additional seats. Thank you!</p>
  </div>
);

export const notify_institution_admin_when_license_seats_are_full = (
  <div className="Body">
    <p className="Text">All current Canopy seats have now been allocated, no new users will be able to join in your existing contract term. Please consider adding more seats to your Canopy license if you foresee additional learners coming onboard.</p>
    <p className="Text">If you have any questions or would like to purchase more seats, please contact your Canopy sales representative or <a href="mailto:info@canopyapps.com">info@canopyapps.com</a>. Thank you!</p>
  </div>
);

const introduction_to_profile_notification_shared = (
  <div className="Body">
    <p className="Text">Your Canopy profile is more than just a collection of information; it's your personal roadmap to language proficiency and success. By sharing some basic details and setting your learning goals, you're taking a significant step toward ensuring that your Canopy experience aligns perfectly with your aspirations.</p>
    <Link className="Link" to="/account/profile">Click here to complete your profile now before you start learning.</Link>
  </div>
);
export const introduction_to_profile_when_user_signs_up = introduction_to_profile_notification_shared;
export const introduction_to_profile_when_user_purchases_a_level = introduction_to_profile_notification_shared;

export const introduce_streaks_if_has_courses_when_user_completes_profile = (
  <div className="Body">
    <p className="Text">In addition to your pledged learning goals in your Canopy Profile, Canopy includes a feature that we believe will enhance your experience and help you stay accountable to your language learning objectives.</p>
    <p className="Text">Canopy Streaks are designed to keep you motivated and accountable to your language learning goals. They encourage consistency and celebrate your successes along the way (or remind you of when you're off track).</p>
    <Link className="Link" to="/">Click here to set your Canopy Streaks settings</Link>
  </div>
);

export const level_1_overview_if_accessible_when_user_completes_profile = (
  <div className="Body">
    <p className="Text">Level 1 of CanopyLearn Medical Spanish is designed to provide you with a strong foundation in medical Spanish and cultural awareness. Here's a glimpse of what you can expect to explore:</p>
    <p className="Text"><strong>Pain Descriptions:</strong> You'll learn to effectively communicate and understand pain descriptions to ensure clear and compassionate patient interactions.</p>
    <p className="Text"><strong>Basic Physical Exam Terms:</strong> Familiarize yourself with fundamental physical examination terminology, empowering you to communicate with both patients and colleagues confidently.</p>
    <p className="Text"><strong>Gastrointestinal, Diabetes, and Cardiovascular Care:</strong> Dive into specialties related to gastrointestinal and cardiovascular care, expanding your medical Spanish proficiency into these important areas.</p>
    <p className="Text">And more.</p>
    <Link className="Link" to="/learn">Click here to jump in and start learning now.</Link>
  </div>
);

const level_2_overview_notification_shared = (
  <div className="Body">
    <p className="Text">Level 2 builds upon your momentum from Level 1 and tackles topics, such as:</p>
    <p className="Text"><strong>Allergies and Pneumonia:</strong> Delve into vocabulary and communication related to respiratory ailments and allergies, ensuring effective patient care and safety.</p>
    <p className="Text"><strong>Pharmacy and Prescriptions:</strong> Learn essential terminology for discussing medications, prescriptions, and pharmacy interactions with confidence.</p>
    <p className="Text"><strong>Substance Abuse and Addiction:</strong> Gain insight into the language surrounding substance abuse and addiction, fostering empathy and understanding in challenging situations.</p>
    <p className="Text">Don't let your momentum slow.</p>
    <Link className="Link" to="/learn">Click here to jump in and start learning now.</Link>
  </div>
);
export const level_2_overview_if_accessible_when_user_completes_profile = level_2_overview_notification_shared;
export const level_2_overview_if_accessible_when_user_completes_level_1 = level_2_overview_notification_shared;

const level_3_overview_notification_shared = (
  <div className="Body">
    <p className="Text">Level 3 is the culmination of your CanopyLearn adventure, and it's designed to equip you with the advanced language skills required in a healthcare setting. Here's a glimpse of the topics you'll explore in this level:</p>
    <p className="Text"><strong>Infant Care:</strong> Learn how to effectively communicate about infant health and care, an essential skill for healthcare professionals and caregivers.</p>
    <p className="Text"><strong>Trauma:</strong> Dive into the vocabulary and communication related to trauma, enhancing your ability to provide support and care in emergency situations.</p>
    <p className="Text"><strong>Cancers:</strong> Gain a deep understanding of the language used in discussing different types of cancers and their treatments.</p>
    <p className="Text"><strong>Depression and Suicide:</strong> Explore the sensitive and critical topics of depression and suicide, empowering you with the language to approach these subjects with empathy and care.</p>
    <p className="Text">Consider this your "capstone" level. Finish strong so you don't lose your progress.</p>
    <Link className="Link" to="/learn">Click here to jump in and start learning now.</Link>
  </div>
);
export const level_3_overview_if_accessible_when_user_completes_profile = level_3_overview_notification_shared;
export const level_3_overview_if_accessible_when_user_completes_level_2 = level_3_overview_notification_shared;

export const guide_about_certificate_when_completes_a_level = (
  <div className="Body">
    <p className="Text">You've earned a certificate of completion!</p>
    <Link className="Link" to="/account/certificate">Click here to get it now.</Link>
  </div>
);

export const remind_to_adjust_settings_before_one_month_of_auto_renewal = (
  <div className="Body">
    <p className="Text">You're all set!</p>
    <p className="Text">This is just a friendly reminder that your CanopyLearn subscription is set to renew in 30 days at our discounted renewal rate. So if you're still learning medical Spanish, no action is needed!</p>
    <p className="Text">
      To make changes, click&nbsp;
      <Link className="Link" to="/account/profile?tab=my-progress&sec=subscriptions">here</Link>&nbsp;
      to review your subscription settings.
    </p>
  </div>
)

export const remind_to_adjust_settings_before_three_days_of_auto_renewal = (
  <div className="Body">
    <p className="Text">[No Action Needed] You're renewing in 3 days!</p>
    <p className="Text">This is just a friendly reminder that your CanopyLearn subscription is renewing in 3 days. If you love Canopy and are planning to continue learning medical Spanish, then no action is needed!</p>
    <p className="Text">
      To make changes, click&nbsp;
      <Link className="Link" to="/account/profile?tab=my-progress&sec=subscriptions">here</Link>&nbsp;
      to review your subscription settings.
    </p>
  </div>
)
