import axios from './AxiosUtil';
import ErrorResponseUtil from './ErrorResponseUtil';

export const fetchLicenses = () => {
    return axios.get('/api/licenses').then(
        res => res.data,
    );
};

export const createLicense = license => {
    return axios.post('/api/licenses', { license }).then(
        res => res.data,
    );
};

export const editLicense = license => {
    return axios.patch(`/api/licenses/${license.id}`, { license }).then(
        res => res.data,
    );
};

export const verifyAccessCode = (accessCode) => {
    return axios.get(`/api/licenses/verify_access_code?access_code=${accessCode}`).then(
        res => res.data
    ).catch(
        err => ErrorResponseUtil(err)
    )
};

export const deleteLicense = licenseId => {
    return axios.delete(`/api/licenses/${licenseId}`).then(
        res => res.data,
    );
};
