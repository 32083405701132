import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import humanizeDuration from 'humanize-duration';

import Loader from '../../../Composed/Loader/Loader';
import Pagination from '../../../Composed/Pagination/Pagination';
import InputBox from '../../../Basic/InputBox/InputBox';

import { clearLearnStats, fetchUserPerformance } from '../../../../Actions/Stats/B2BAdmin/LearnActions';

import { courseSeriesList } from '../../../../Constants/CourseConstants';
import { average, hashToQueryString } from '../../../../Util/Helper/GenericUtil';

const mapStateToProps = (state) => ({
  performances: (state.stats.b2bAdmin.learns.userPerformance || {}),
  filters: (state.filters || {})
});

const mapDispatchToProps = (dispatch) => ({
  clearLearnStats: (key) => dispatch(clearLearnStats(key)),
  fetchUserPerformance: (queryString) => dispatch(fetchUserPerformance(queryString))
});

const UserPerformance = ({ performances, filters, clearLearnStats, fetchUserPerformance, contentWidth }) => {
  const [loading, setLoading] = useState(true);
  const [levelTab, setLevelTab] = useState(0);
  const [statsTab, setStatsTab] = useState('result');
  const [searchFilter, setSearchFilter] = useState('');
  const [triggerSearch, setTriggerSearch] = useState('');
  const [page, setPage] = useState(1);
  const [per, setPer] = useState(10);

  let humanizeConfig = { units: ['d', 'h', 'm', 's'], round: true, conjunction: ' & ' };

  useEffect(() => {
    setLoading(true);
    clearLearnStats('userPerformance');
    fetchUserPerformance(buildQueryString()).then(() => setLoading(false));
  }, [clearLearnStats, fetchUserPerformance, filters, page, per, triggerSearch]);

  const buildQueryString = () => {
    let queryString = '';

    queryString += hashToQueryString(filters);
    queryString += `&page=${page}&per=${per}`;
    queryString += `&search_filter=${triggerSearch}`;

    return queryString;
  }

  const statClass = (lesson, stat) => {
    if (statsTab === 'result') {
      if (lesson.quiz_id && stat) {
        return (stat > 70 ? 'Pass' : 'Fail');
      } else {
        return 'N/A';
      }
    } else if (statsTab === 'progress') {
      if (lesson.slides && stat) {
        return (stat >= lesson.slides ? 'Pass' : 'Fail');
      } else {
        return 'N/A';
      }
    } else if (statsTab === 'time') {
      if (lesson.slides && stat) {
        return stat > 0 ? 'Pass' : 'Fail';
      } else {
        return 'N/A';
      }
    }

    return 'N/A';
  }

  const statValue = (lesson, stat) => {
    if (statsTab === 'result') {
      if (!lesson.quiz_id) {
        return '-';
      } else if (!stat) {
        return 'N/A';
      } else {
        return `${stat}%`;
      }
    } else if (statsTab === 'progress') {
      if (!lesson.slides) {
        return '-';
      } else if (!stat) {
        return 'N/A';
      } else {
        return `${Math.min(stat, lesson.slides)} of ${lesson.slides}`;
      }
    } else if (statsTab === 'time') {
      if (!lesson.slides) {
        return '-';
      } else if (!stat) {
        return 'N/A';
      } else {
        return humanizeDuration(stat * 1000, humanizeConfig);
      }
    }

    return 'N/A';
  }

  const sortedLessons = (course) => (
    Object.values(course.lessons).sort((a, b) => a.position > b.position ? 1 : -1)
  )

  const averagePerformance = () => (
    <div className="Average-Performance-Container">
      <div
        className="Dynamic-Width"
        style={{
          width: `${contentWidth}px`
        }}
      >
        {
          Object.values(performances).map(course => (
            <table key={`avg-tm-qz-gd-table-${course.id}`} className="Table">
              <thead>
                <tr>
                  <th>{`Level ${course.level} Lessons`}</th>
                  {
                    sortedLessons(course).map(lesson => (
                      <th key={`avg-tm-qz-gd-th-${course.id}-${lesson.name}`}>
                        {lesson.name}
                      </th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Avg Time Spent</td>
                  {
                    sortedLessons(course).map(lesson => (
                      <td key={`avg-tm-td-${course.id}-${lesson.name}`}>
                        {lesson.slides ? humanizeDuration(lesson.average_time * 1000, humanizeConfig) : '-'}
                      </td>
                    ))
                  }
                </tr>
                <tr>
                  <td>Avg Quiz Grade</td>
                  {
                    sortedLessons(course).map(lesson => (
                      <td key={`qz-gd-td-${course.id}-${lesson.name}`}>
                        {lesson.quiz_id ? `${lesson.average_result || 0}%` : '-'}
                      </td>
                    ))
                  }
                </tr>
              </tbody>
            </table>
          ))
        }
      </div>
      <div className="Notes">
        <ul className="list-disc pl-10">
          <li>A hyphen in the "Time Spent" indicates that the system does not track time for them, which is usually the case for assessments and quizzes.</li>
          <li>A hyphen in the "Quiz Grade" indicates that there is no quiz under that particular lesson.</li>
        </ul>
      </div>
    </div>
  )

  const usersPerformance = () => {
    let activeCourse = Object.values(performances)[levelTab];
    let lessons = sortedLessons(activeCourse);
    let users = Object.values(lessons[0].users || {});

    return (
      <>
        <div className="Users-Performance-Container">
          <div className="Tabs-Container">
            <div className="Tab-Holder">
              {
                Object.values(performances).map((course, idx) => (
                  <div
                    key={`tab-${idx}`}
                    children={`${courseSeriesList[course.series]} Level ${course.level}`}
                    className={`Tab ${levelTab === idx ? 'Active' : ''}`}
                    onClick={() => setLevelTab(idx)}
                  />
                ))
              }
            </div>

            <div className="SearchBar">
              <InputBox
                className="Input"
                placeholder="First Name, Last Name, Email"
                value={searchFilter}
                onChange={e => setSearchFilter(e.currentTarget.value)}
                onKeyPress={e => (e.key === 'Enter' && e.currentTarget.blur())}
                onBlur={e => {
                  setPage(1);
                  setTriggerSearch(searchFilter);
                }}
              />
            </div>

            <div className="Filter-Holder">
              <select
                className="Records-Per-Page"
                value={per}
                onChange={(e) => {
                  setPage(1);
                  setPer(e.currentTarget.value);
                }}
              >
                <option value="10">Show 10 users</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>

          <div className="Stats-Tabs">
            <div
              className={`Stats-Tab ${statsTab === 'result' && 'Active'}`}
              onClick={() => setStatsTab('result')}
            >
              Quiz Grades
            </div>

            <div
              className={`Stats-Tab ${statsTab === 'progress' && 'Active'}`}
              onClick={() => setStatsTab('progress')}
            >
              Slides Viewed
            </div>

            <div
              className={`Stats-Tab ${statsTab === 'time' && 'Active'}`}
              onClick={() => setStatsTab('time')}
            >
              Time Spent
            </div>
          </div>

          <div
            className="Content Dynamic-Width"
            style={{
              width: `${contentWidth}px`
            }}
          >
            <table className="Table">
              <thead>
                <tr>
                  <th>{`Level ${activeCourse.level} Lessons`}</th>
                  {
                    lessons.map(lesson => (
                      <th key={`usr-pf-th-${lesson.name}`}>{lesson.name}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  users.map(user => (
                    <tr key={`usr-pf-tr-${user.id}`}>
                      <td>
                        <span className="User">
                          {user.name}
                        </span>
                      </td>
                      {
                        lessons.map(lesson => {
                          let stat = lesson.users?.[user.id]?.[statsTab];

                          return (
                            <td key={`usr-pf-td-${user.id}-${lesson.id}`}>
                              <span className={statClass(lesson, stat)}>
                                {statValue(lesson, stat)}
                              </span>
                            </td>
                          )
                        })
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className="Notes">
            <ul className="list-disc pl-10">
              <li>Time is not tracked if users go through slides quickly and spend less than 5 seconds on each one.</li>
            </ul>
          </div>
        </div>

        <Pagination
          totalRecords={activeCourse.totalUsers}
          page={page}
          setPage={(p) => setPage(p)}
          per={per}
        />
      </>
    )
  }

  const renderEmpty = () => (
    <div className="No-Data">
      The users has not made any progress yet!
    </div>
  );

  const renderContent = () => {
    if (loading)
      return <Loader />

    if (Object.keys(performances).length === 0)
      return renderEmpty();

    return (
      <>
        {averagePerformance()}
        {usersPerformance()}
      </>
    )
  }

  return (
    <div className="User-Performance">
      <h2 className="Title">User Performance</h2>
      {renderContent()}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPerformance);
