import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';

import './StackedChart.scss';

Exporting(Highcharts);

const StackedChart = ({ id, data }) => {
  const buildToolTip = (that) => (
    data.tooltip
        .replaceAll('{{POINT_0_X}}', that.points[0]?.x)
        .replaceAll('{{POINT_0_POINT_TEMPLATE}}', that.points[0]?.point?.template)
        .replaceAll('{{POINT_0_y}}', that.points[0]?.y)
        .replaceAll('{{POINT_0_POINT_QUESTION_NUM}}', that.points[0]?.point?.questionNum)
        .replaceAll('{{POINT_0_POINT_QUESTION}}', that.points[0]?.point?.question)
        .replaceAll('{{POINT_0_COLOR}}', that.points[0]?.color)
        .replaceAll('{{POINT_0_PERCENTAGE}}', Math.round(that.points[0]?.percentage || 0))
        .replaceAll('{{POINT_0_POINT_USERS}}', that.points[0]?.point?.users)
        .replaceAll('{{POINT_0_POINT_FULL_DATE}}', that.points[0]?.point?.fullDate)
        .replaceAll('{{POINT_0_POINT_TOTAL}}', that.points[0]?.y)
        .replaceAll('{{POINT_1_y}}', that.points[1]?.y)
        .replaceAll('{{POINT_1_COLOR}}', that.points[1]?.color)
        .replaceAll('{{POINT_1_PERCENTAGE}}', Math.round(that.points[1]?.percentage || 0))
        .replaceAll('{{POINT_1_POINT_TOTAL}}', that.points[1]?.y)
        .replaceAll('{{POINT_1_POINT_USERS}}', that.points[1]?.point?.users)
        .replaceAll('{{POINT_2_COLOR}}', that.points[2]?.color)
        .replaceAll('{{POINT_2_POINT_USERS}}', that.points[2]?.point?.users)
        .replaceAll('{{POINT_2_POINT_TOTAL}}', that.points[2]?.y)
  );

  Highcharts.chart(id, {
    chart: {
      type: 'column'
    },
    title: {
      text: data.title,
      align: 'left',
      x: 20
    },
    subtitle: {
      text: data.subtitle,
      y: 200,
    },
    xAxis: {
      categories: data.xAxis
    },
    yAxis: {
      min: 0,
      opposite: true,
      title: {
        text: data.yAxisTitle
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: 'bold',
          color: (
            Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color
          ) || 'gray'
        }
      }
    },
    legend: {
      title: {
        text: data.legendTitle,
        style: {
          fontStyle: 'italic'
        }
      },
      layout: 'horizontal',
      align: 'right',
      x: -50,
      verticalAlign: 'top',
      y: 12,
      floating: true,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function() {
        return buildToolTip(this)
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointWidth: '40',
        dataLabels: {
          enabled: false
        }
      }
    },
    series: data.series
  });

  return null
}

export default StackedChart;
