import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import ProfileNavBar from '../NavBars/ProfileNavBar/ProfileNavBarContainer';
import Packages from './Packages/Packages';
import Purchase from './Purchase/Purchase';

import './Onboarding.scss';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

const Onboarding = () => {
  return (
    <div className="App Onboarding">
      <div className="Grey-Background">
        <ProfileNavBar logoOnly={true} />

        <Switch>
          <Route
            path="/start-learning/package/:coursePackageId"
            component={props =>
              <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_KEY)}>
                <ElementsConsumer>
                  {({ stripe, elements }) => <Purchase stripe={stripe} elements={elements} {...props} />}
                </ElementsConsumer>
              </Elements>
            }
          />

          <Route
            exact
            path="/start-learning"
            component={Packages}
          />
        </Switch>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
