import * as APIUtil from '../../../Util/Stats/TEAdmin/InstitutionsApiUtil';
import { ExcelDownloader } from '../../../Util/Helper/ExcelUtil';

export const RECEIVE_TE_ADMIN_INSTITUTIONS_STATS = 'RECEIVE_TE_ADMIN_INSTITUTIONS_STATS';
export const RECEIVE_TE_ADMIN_INSTITUTIONS_STATS_ERRORS = 'RECEIVE_TE_ADMIN_INSTITUTIONS_STATS_ERRORS';
export const CLEAR_TE_ADMIN_INSTITUTIONS_STATS = 'CLEAR_TE_ADMIN_INSTITUTIONS_STATS';

export const receiveTEAdminInstitutionsStats = (payload) => ({
  type: RECEIVE_TE_ADMIN_INSTITUTIONS_STATS,
  payload,
});

export const receiveTEAdminInstitutionsStatsErrors = (errors) => ({
  type: RECEIVE_TE_ADMIN_INSTITUTIONS_STATS_ERRORS,
  errors,
});

export const clearTEAdminInstitutionsStats = () => ({
  type: CLEAR_TE_ADMIN_INSTITUTIONS_STATS,
});

export const fetchTEAdminInstitutionsStats = (accessToken, institutionsFilter, normalFilters, comparisonFilters) => dispatch => {
  const excelDownloader = ExcelDownloader('organizations');

  excelDownloader.addSheetWithRows('Filters', [{
    'Institutions': Object.values(institutionsFilter).join(', '),
    'Start Date': normalFilters.start_date,
    'End Date': normalFilters.end_date,
    'Course Name': normalFilters.course_name,
    'Comparison Start Date': comparisonFilters.start_date,
    'Comparison End Date': comparisonFilters.end_date,
    'Comparison Course Name': comparisonFilters.course_name,
  }]);

  const supplyChunk = (chunk) => {
    if (chunk.event === 'streaming') {
      if (excelDownloader.hasSheet('Organizations')) {
        excelDownloader.appendRowsToSheet('Organizations', chunk.rows);
      } else {
        excelDownloader.addSheetWithRows('Organizations', chunk.rows);
      }

      dispatch(receiveTEAdminInstitutionsStats({ total_licenses: chunk.total_licenses, chunk_licenses: chunk.chunk_licenses }));
    }

    if (chunk.event === 'closed') {
      excelDownloader.applyFormulas('Organizations', { 'Mean': 'AVERAGE', 'Min': 'MIN', 'Max': 'MAX', 'Median': 'MEDIAN', 'Sum': 'SUM' });
      excelDownloader.download();
    }
  }

  return APIUtil.fetchTEAdminInstitutionsStats(
    accessToken,
    institutionsFilter,
    normalFilters,
    comparisonFilters,
    (chunk) => supplyChunk(chunk),
  )
}
