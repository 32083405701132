import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { fetchCanopySubscriptions, clearCanopySubscriptions } from '../../../../Actions/CanopySubscriptionActions';
import { fetchRecurringSubscriptions, clearRecurringSubscriptions, cancelRecurringSubscription } from '../../../../Actions/RecurringSubscriptionActions';
import { humanizeDate } from '../../../../Util/Helper/GenericUtil';
import { gaEvent } from '../../../../Util/Helper/GoogleUtil';

import UserResourcesContainer from '../UserResources/UserResourcesContainer';
import Loader from '../../../Composed/Loader/Loader';
import MirrorLoader from '../../../Composed/Loader/MirrorLoader';
import Modal from '../../../Composed/Modal/Modal';
import ImageBox from '../../../Basic/ImageBox/ImageBox';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import CertificateLocator from '../../../Composed/CertificateLocator/CertificateLocator';

import './Subscriptions.scss';

const {
  REACT_APP_ONE_DOMAIN
} = process.env;

const mapStateToProps = (state) => ({
  canopySubscriptions: state.entities.canopySubscriptions,
  recurringSubscriptions: state.entities.recurringSubscriptions
});

const mapDispatchToProps = (dispatch) => ({
  fetchCanopySubscriptions: () => (dispatch(fetchCanopySubscriptions())),
  clearCanopySubscriptions: () => (dispatch(clearCanopySubscriptions())),
  fetchRecurringSubscriptions: () => (dispatch(fetchRecurringSubscriptions())),
  clearRecurringSubscriptions: () => (dispatch(clearRecurringSubscriptions())),
  cancelRecurringSubscription: (recurringSubscriptionId) => (dispatch(cancelRecurringSubscription(recurringSubscriptionId)))
});

const Subscriptions = ({
  canopySubscriptions, recurringSubscriptions,
  fetchCanopySubscriptions, clearCanopySubscriptions,
  fetchRecurringSubscriptions, clearRecurringSubscriptions, cancelRecurringSubscription
}) => {
  const [loadingRecurringSubscription, setLoadingRecurringSubscription] = useState(true);
  const [loadingCanopySubscription, setLoadingCanopySubscription] = useState(true);
  const [processing, setProcessing] = useState(false);

  const queryString = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (!loadingRecurringSubscription && !loadingCanopySubscription && queryString.has('sec') && (queryString.get('sec') === 'subscriptions')) {
      const scrollElement = document.getElementById('My-Progress-Subscriptions');
      window.scrollTo({ top: (scrollElement.offsetTop - 80), behavior: 'smooth' });
    }
  }, [loadingRecurringSubscription, loadingCanopySubscription, queryString]);

  useEffect(() => {
    clearRecurringSubscriptions();
    fetchRecurringSubscriptions().then(res => setLoadingRecurringSubscription(false));
  }, [clearRecurringSubscriptions, fetchRecurringSubscriptions]);

  useEffect(() => {
    clearCanopySubscriptions();
    fetchCanopySubscriptions().then(res => setLoadingCanopySubscription(false));
  }, [clearCanopySubscriptions, fetchCanopySubscriptions]);

  const handleCancelRecurringSubscription = (recurringSubscription, close) => {
    setProcessing(true);
    cancelRecurringSubscription(recurringSubscription.id).then(res => {
      gaEvent('recurring_subscription_cancel', { recurring_subscription_id: recurringSubscription.id });
      setProcessing(false);
      close();
    })
  }

  if (loadingRecurringSubscription || loadingCanopySubscription) {
    return <Loader />
  }

  return (
    <div className="My-Progress" id="My-Progress-Subscriptions">
      {
        processing && <MirrorLoader message="Processing!" />
      }

      <div className="ProgressContainer">
        <div className="TitleContainer">
          <div className="ProgressTitle">
            <div className="Title">
              Subscription & Achievements
            </div>
            <CertificateLocator />
          </div>
        </div>

        <div className="Recurring-Subscriptions">
          {
            Object.values(recurringSubscriptions || {}).map(recurringSubscription => (
              <div className="Recurring-Subscription">
                <div className="Information Responsive-To-Screen">
                  <div className="Piece">
                    <span className="Bold">Package:</span>&nbsp;{recurringSubscription.coursePackageName}
                    {
                      recurringSubscription.auto_renew &&
                      <div className="AutoRenewal-Link">
                        <Modal
                          title="Are you sure?"
                          subTitle={`This will cancel your subscription as of ${humanizeDate(recurringSubscription.auto_renew_upcoming)}. Are you sure?`}
                          form={close =>
                            <div className="buttons Auto-Renew-Buttons">
                              <ButtonBox
                                className="Yes-Cancel-Subscription-Button"
                                text={<p>YES</p>}
                                onClick={e => handleCancelRecurringSubscription(recurringSubscription, close)}
                              />

                              <ButtonBox
                                className="No-Cancel-Subscription-Button"
                                text={<p>Return to Subscription Page</p>}
                                onClick={close}
                              />
                            </div>
                          }
                        >
                          {
                            open =>
                            <ButtonBox
                              className="B2BAdminDashboard-Button Turn-Off"
                              text='Turn Auto Renew Off'
                              onClick={open}
                            />
                          }
                        </Modal>
                      </div>
                    }
                  </div>
                  <div className="Piece"><span className="Bold">Last Purchase:</span>&nbsp; {humanizeDate(recurringSubscription.auto_renewed_at)}</div>
                  <div className="Piece"><span className="Bold">Upcoming Renewal:</span>&nbsp; {humanizeDate(recurringSubscription.auto_renew_upcoming) || 'Cancelled'}</div>
                  {
                    recurringSubscription.auto_renew &&
                    recurringSubscription.coursePackageRenewalPrice &&
                    <div className="Piece"><span className="Bold">Renewal Cost:</span>&nbsp; ${(recurringSubscription.coursePackageRenewalPrice / 100)}</div>
                  }
                  <div className="Piece"><span className="Bold">Includes below items</span></div>
                </div>
                <div className="ProgressTable Responsive-To-Screen">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Accessed via</th>
                        <th scope="col">Achievement</th>
                        <th scope="col">
                          <ImageBox className="RelateIcon" src="related.svg" />
                          {' '}
                          Related Resources
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        Object.values(recurringSubscription.learn || {}).map(sub => (
                          <tr>
                            <td>{sub.course.name}</td>
                            <td>{humanizeDate(sub.created_at)}</td>
                            <td>{humanizeDate(sub.expires_at)}</td>
                            <td>{recurringSubscription.accessedVia}</td>
                            <td className="Achievement">
                              {
                                sub.certificate ? (
                                  <Link to="/account/certificate">View Certificate of Completion</Link>
                                ) : (
                                  '—'
                                )
                              }
                            </td>
                            <td className="Related-Resource">—</td>
                          </tr>
                        ))
                      }

                      {
                        Object.values(recurringSubscription.credential || {}).map(canopyLicense => (
                          Object.values(canopyLicense.exams || {}).map(exam => (
                            <tr>
                              <td>{`Credential ${exam.language}`}</td>
                              <td>{humanizeDate(canopyLicense.startDate)}</td>
                              <td>{humanizeDate(canopyLicense.endDate)}</td>
                              <td>{recurringSubscription.accessedVia}</td>
                              <td className="Achievement">
                                {
                                  exam.scorecard ? (
                                    <a href={REACT_APP_ONE_DOMAIN} target="_blank" rel="noopener noreferrer">View Latest Scorecard</a>
                                  ) : (
                                    '—'
                                  )
                                }
                              </td>
                              <td className="Related-Resource">—</td>
                            </tr>
                          ))
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            ))
          }
        </div>

        {
          (Object.keys(canopySubscriptions.learn || {}).length !== 0 || Object.keys(canopySubscriptions.credential || {}).length !== 0) &&
          <div className="Canopy-Subscriptions">
            <div className="Canopy-Subscription">
              <div className="Information Responsive-To-Screen">
                <div className="Piece"><span className="Bold">One Time Subscriptions</span></div>
              </div>
                <div className="ProgressTable Responsive-To-Screen">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Active</th>
                        <th scope="col">Accessed via</th>
                        <th scope="col">Achievement</th>
                        <th scope="col">
                          <ImageBox className="RelateIcon" src="related.svg" />
                          {' '}
                          Related Resources
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        Object.values(canopySubscriptions.learn || {}).map(sub => (
                          <tr>
                            <td>{sub.product}</td>
                            <td>{humanizeDate(sub.startDate)}</td>
                            <td>{humanizeDate(sub.endDate)}</td>
                            <td>{sub.active}</td>
                            <td>{sub.accessedVia}</td>
                            <td className="Achievement">
                              {
                                sub.certificate ? (
                                  <Link to="/account/certificate">View Certificate of Completion</Link>
                                ) : (
                                  '—'
                                )
                              }
                            </td>
                            <td className="Related-Resource">—</td>
                          </tr>
                        ))
                      }

                      {
                        Object.values(canopySubscriptions.credential || {}).map(canopyLicense => (
                          <tr>
                            <td>{canopyLicense.product}</td>
                            <td>{humanizeDate(canopyLicense.startDate)}</td>
                            <td>{humanizeDate(canopyLicense.endDate)}</td>
                            <td>{canopyLicense.active}</td>
                            <td>{canopyLicense.accessedVia}</td>
                            <td className="Achievement">
                              {
                                canopyLicense.scorecard ? (
                                  <a href={REACT_APP_ONE_DOMAIN} target="_blank" rel="noopener noreferrer">View Latest Scorecard</a>
                                ) : (
                                  '—'
                                )
                              }
                            </td>
                            <td className="Related-Resource">—</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
        }

        <div className="Canopy-User-Resources">
          <div className="Canopy-User-Resource">
            <div className="Information Responsive-To-Screen">
              <div className="Piece"><span className="Bold">Achievements</span></div>
            </div>
            <UserResourcesContainer />
          </div>
        </div>
      </div>
    </div>
  )
}

Subscriptions.propTypes = {
  canopySubscriptions: PropTypes.object.isRequired,
  fetchCanopySubscriptions: PropTypes.func.isRequired,
  clearCanopySubscriptions: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
