import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './CreationCoursesDashboard.css';

import { COLORS } from '../../../Style/Style';
import TextBox from '../../Basic/TextBox/TextBox';
import CircularProgressBar from '../../Basic/CircularProgressBar/CircularProgressBar';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import Modal from '../../Composed/Modal/Modal';
import CreateEditCourse from '../../Forms/CreateEditCourse/CreateEditCourse';
import EditCourseType from '../../Forms/EditCourseType/EditCourseType';
import ErrorFlashBox  from '../../Composed/ErrorFlashBox/ErrorsFlashBox';

class CreationCoursesDashboard extends Component {
  componentDidMount() {
    let queryString = new URLSearchParams(this.props.location.search);

    this.props.clearCourseErrors();
    this.props.clearLessons();
    this.props.clearCourseId();

    if (queryString.has('nextLes')) {
      setTimeout(() => {
        let pathHash = window.location.hash.split('?');
        window.location.hash = `${pathHash[0]}lessons/?${pathHash[1]}`;
      }, 2000);
    }
  }

  handleCourseRender = () => {
    if (Object.keys(this.props.courses).length > 0) {
      let courses = Object.keys(this.props.courses).map((key) => {
        const course = this.props.courses[key];
        return (
          <div className="CreationDashboards-Course-Box"
            key={key}>
            <div
              className="CreationDashboards-Course-Title">
              <TextBox
                text={course.name}
                style={{
                  color: `${COLORS.Blue}`,
                  fontWeight: "600",
                  textDecoration: "none",
                }}
                tag="h5"
              />
            </div>
            <div
              className="CreationDashboards-Course-CircularProgressBar">
              <CircularProgressBar
                totalNumberOfSteps={course.lessons?.length}
                completedSteps={ 0 }
                showText={true}
              />
            </div>
            <div
              className="CreationDashboards-Course-Button">
              <Link to={`/creation/courses/${course.id}/lessons`}>
                <ButtonBox
                  className="CreationDashboards-ButtonBox"
                  text="Edit Course" />
              </Link>
            </div>
          </div>
        )
      })
      return courses;
    }
  }

  render() {
    const { errors, flash, courseTypes } = this.props;

    return (
      <div>
        <div
        className="CreationDashboards">
          <div className="CreationDashboard-CourseTypes">
            <h3 className="CourseTypes-Title">Course Types</h3>
            <div className="CourseTypes">
              {
                Object.values(courseTypes).map((courseType, idx) => {
                  return (
                    <div key={idx} className="CourseType">
                      <Modal
                        title={`Edit ${courseType.name} Settings`}
                        form={
                          close =>
                          <EditCourseType
                            courseType={courseType}
                            updateCourseType={this.props.updateCourseType}
                            closeModal={close}
                          />
                        }
                      >
                        {
                          open =>
                          <ButtonBox
                            className="CreationDashboards-ButtonBox"
                            text={ courseType.name }
                            onClick={open}
                          />
                        }
                      </Modal>
                    </div>
                  )
                })
              }
            </div>
          </div>

          <div
            className="CreationDashboards-Menu">
            <TextBox
              text="Courses"
              style={{
                color: `${COLORS.Blue}`,
                fontWeight: "600",
                textDecoration: "none",
              }}
              tag="h3"
            />

            <Modal
              title={"Add A Course"}
              form={
                close => 
                  <CreateEditCourse
                    closeModal={close}
                    submitCourse={this.props.createCourse}
                    courseTypes={courseTypes}
                  />
              }>
              {
                open =>
                  <div
                    className="CreationDashboards-Menu-Button">
                      <ButtonBox
                        className="CreationDashboards-ButtonBox"
                        text="Add Course"
                        onClick={open}/>
                  </div>
              }
            </Modal>
          </div>

          <ErrorFlashBox
            errors={errors}
            flash={flash} />

          <div
            className="CreationDashboards-DataBox">
          { this.handleCourseRender() }
          </div>
        </div>
      </div>
    );
  }
}

export default CreationCoursesDashboard;
