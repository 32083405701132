export const courseSeriesList = {
  'MS': 'Medical Spanish',
  'ME': 'Medical English',
  'IM': 'Medical English (Spanish)',
  'EC': 'Medical English (Chinese)',
  'COM': 'Compliance',
  'IFD': 'Infectious Disease',
  'BMS': 'Beginner Spanish',
}

export const courseLanguageList = {
  'SP': 'Spanish',
  'EN': 'English',
}

export const activitySupportedSectionTypes = ['video', 'text', 'multiple_choice'];
export const topicSupportedSectionTypes = ['video', 'text', 'dialog', 'flashcard', 'table_fill_in', 'sorting', 'multiple_choice', 'word_scramble', 'multiple_answer', 'fill_in_the_blank', 'true_or_false', 'matching', 'drag_and_drop', 'drop_down', 'sentence_ordering']
export const quizSupportedSectionTypes = ['text', 'multiple_choice', 'word_scramble', 'multiple_answer', 'fill_in_the_blank', 'true_or_false', 'matching', 'drag_and_drop', 'drop_down', 'sentence_ordering']
