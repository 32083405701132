import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactNumberFormat from 'react-number-format';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import Process from '../Process/Process';
import CountryDropdown from '../../Composed/CountryDropdown/CountryDropdown';
import TextBox from '../../Basic/TextBox/TextBox';
import InputBox from '../../Basic/InputBox/InputBox';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';

import { microsoftAdScript } from '../../../Util/Helper/MicrosoftUtil';
import { verifyCoupon } from '../../../Util/Onboarding/ChargeApiUtil';
import { payablePrice, discountedPrice, appliedDiscount } from '../../../Util/Helper/StripeUtil';

import './Purchase.scss';

const mapStateToProps = (state, { match }) => ({
  iUser: state.onboarding.users,
  iCharge: state.onboarding.charges,
  coursePackage: state.entities.coursePackages[match.params.coursePackageId],
  premiumPackage: Object.values(state.entities.coursePackages).find(cp => cp.courses?.length > 1)
});

const mapDispatchToProps = (dispatch) => ({
});

const Purchase = ({ stripe, elements, iUser, iCharge, coursePackage, premiumPackage }) => {
  const [proceed, setProceed] = useState(false);
  const [user, setUser] = useState({
    first_name:       { value: '', complete: false, error: true },
    last_name:        { value: '', complete: false, error: true },
    email:            { value: '', complete: false, error: true },
    confirm_email:    { value: '', complete: false, error: true },
  });
  const [password, setPassword] = useState({
    password:         { value: '', complete: false, error: true },
    confirm_password: { value: '', complete: false, error: true },
  });
  const [card, setCard] = useState({
    number:  { value: '', complete: false, error: true },
    expiry:  { value: '', complete: false, error: true },
    cvc:     { value: '', complete: false, error: true },
    name:    { value: '', complete: false, error: true },
    country: { value: '', complete: false, error: true },
    zip:     { value: '', complete: false, error: true }
  });
  const [coupon, setCoupon] = useState({ applying: false, code: '', error: '', data: null });

  useEffect(() => {
    microsoftAdScript();
  }, []);

  const applyCouponCode = async (e) => {
    e.preventDefault();

    if (coupon.code === '') {
      setCoupon({ ...coupon, applying: false, error: 'Please provide a coupon code!', data: null });
      return null;
    }

    setCoupon({ ...coupon, applying: true, error: '', data: null });

    try {
      const response = await verifyCoupon(coupon.code, coursePackage.id);
      setCoupon({ ...coupon, applying: false, error: '', data: response });
    } catch (error) {
      setCoupon({ ...coupon, applying: false, error: error.response.data.error, data: null });
    }
  }

  const handleUserInputChange = (field, e) => {
    let newUser = { ...user };

    newUser[field].value = e.currentTarget.value;
    newUser[field].complete = (e.currentTarget.value.length > 0);

    if (field === 'email') {
      newUser[field].error = !(/^\S+@\S+\.\S+$/.test(e.currentTarget.value));
      newUser['confirm_email'].error = (e.currentTarget.value !== user.confirm_email.value);
    } else if (field === 'confirm_email') {
      newUser[field].error = (e.currentTarget.value !== user.email.value);
    } else if (field === 'first_name') {
      newUser[field].error = (e.currentTarget.value.length === 0);
    } else if (field === 'last_name') {
      newUser[field].error = (e.currentTarget.value.length === 0);
    }

    setUser(newUser);
  }

  const handlePasswordInputChange = (field, e) => {
    let newPassword = { ...password };

    newPassword[field].value = e.currentTarget.value;
    newPassword[field].complete = (e.currentTarget.value.length > 0);

    if (field === 'password') {
      newPassword[field].error = !(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-z\d!@#$%^&*?~]{8,}$/.test(e.currentTarget.value));
      newPassword['confirm_password'].error = (e.currentTarget.value !== password.confirm_password.value);
    } else if (field === 'confirm_password') {
      newPassword[field].error = (e.currentTarget.value !== password.password.value);
    }

    setPassword(newPassword);
  }

  const handleCardInputChange = (field, e) => {
    let newCard = { ...card };

    newCard[field].value = e.currentTarget.value;
    newCard[field].complete = (e.currentTarget.value.length > 0);

    if (field === 'name') {
      newCard[field].error = (e.currentTarget.value.length === 0);
    } else if (field === 'country') {
      newCard[field].error = (e.currentTarget.value === '');
    } else if (field === 'zip') {
      newCard[field].error = !(/^\d{5}$/.test(e.currentTarget.value));
    }

    setCard(newCard);
  }

  const handleCardElementChange = (field, complete, error) => {
    setCard({
      ...card, [field]: { ...card[field], complete: complete, error: error }
    });
  }

  const disableProceed = () => (
    (
      Object.values(user).find(v => !v.complete || v.error)
      ||
      Object.values(card).find(v => !v.complete || v.error)
    ) ? true : false
  )

  return (
    <div className="Purchase">
      {
        proceed &&
        <Process
          stripe={stripe}
          cardNumberElement={elements.getElement(CardNumberElement)}
          user={user}
          password={password}
          passwordChange={(field, e) => handlePasswordInputChange(field, e)}
          card={card}
          coupon={coupon}
          coursePackage={coursePackage}
          abort={() => setProceed(false)}
        />
      }

      <div className="Return">
        <Link to="/start-learning">
          <i className="fa fa-angle-left" />
          <img src="favicon.ico" />
          <span>canopy</span>
        </Link>
      </div>

      <div className="Content">
        <div className="Details">
          <div className="Congratulations">
            <TextBox className="Title" text="Congratulations on choosing" />
            <TextBox className="PackageName" text={coursePackage.name} />
            <ReactNumberFormat
              className={`Price ${coupon.data ? 'Strike-Through' : ''}`}
              displayType="text"
              value={coursePackage.price / 100}
              prefix="$"
              decimalScale="2"
              allowNegative={false}
              thousandSeparator
            />
            {
              coupon.data?.coupon &&
              <ReactNumberFormat
                className="Discounted-Price"
                displayType="text"
                value={payablePrice(coursePackage, coupon.data)}
                prefix="$"
                decimalScale="2"
                allowNegative={false}
                thousandSeparator
              />
            }
            {
              false &&
              <div className="PremiumPackage">
                <p>Select <u>Preimum Package</u> if you would like to take advantage of the following:</p>
                <ul>
                  <li>Full Access to Canopy Learn content ({premiumPackage.name})</li>
                  <li>Extended {premiumPackage.length} Month Access to course content</li>
                </ul>
              </div>
            }
          </div>

          <div className="Summary">
            <div className="Head">
              Order Summary
            </div>
            <div className="Body">
              <div className="Info">
                <div className="Left">{coursePackage.name}<br />{coursePackage.length} Month Individual Subscription</div>
                <div className="Right">${coursePackage.price / 100}</div>
              </div>
              {
                coupon.data?.coupon &&
                <div className="Info">
                  <div className="Left">Coupon</div>
                  <div className="Right">-${discountedPrice(coursePackage, coupon.data)}</div>
                </div>
              }
              <div className="Divider" />
              <div className="Info">
                <div className="Left">Total</div>
                <div className="Right">
                  ${coupon.data?.coupon ? (payablePrice(coursePackage, coupon.data)) : (coursePackage.price / 100)}
                </div>
              </div>
            </div>
          </div>

          <div className="Terms-Privacy">
            By enrolling, you agree to our &nbsp;
            <a href="terms_of_service.pdf" target="_blank"><u>Terms of Use</u></a>
            &nbsp; and &nbsp;
            <a href="privacy_policy.pdf" target="_blank"><u>Privacy Policy</u></a>
          </div>

          <div className="Coupon">
            <div className="Field">
              <input
                className="Input"
                value={coupon.code}
                placeholder="Promotion Code"
                onChange={(e) => setCoupon({ ...coupon, code: e.currentTarget.value })}
              />
            </div>

            {
              coupon.error &&
              <div className="Error">
                {coupon.error}
              </div>
            }

            {
              coupon.data &&
              <div className="Applied">
                <div className="Info">
                  <TextBox text="Coupon Applied" />
                  <TextBox text={coupon.data.coupon?.name} />
                  <TextBox text={appliedDiscount(coupon.data)} />
                </div>
              </div>
            }

            <div className="Submit">
              <ButtonBox
                className="Btn"
                text="Apply Code"
                onClick={(e) => applyCouponCode(e)}
                disabled={coupon.applying}
              />
            </div>
          </div>
        </div>

        <div className="Form">
          <div className="Group">
            <div className="Title">Contact Information</div>

            {
              iUser.error &&
              <div className="Form-Error">{iUser.error}</div>
            }

            <div className="Field">
              <label>Email</label>
              <input
                className={user.email.complete && user.email.error ? 'Error' : ''}
                value={user.email.value}
                onChange={(e) => handleUserInputChange('email', e)}
              />
            </div>

            <div className="Field">
              <label>Confirm Email</label>
              <input
                className={user.confirm_email.complete && user.confirm_email.error ? 'Error' : ''}
                value={user.confirm_email.value}
                onChange={(e) => handleUserInputChange('confirm_email', e)}
              />
            </div>

            <div className="Field">
              <label>Name</label>
              <div className="Duo-Horizontal">
                <InputBox
                  className={`Left ${user.first_name.complete && user.first_name.error ? 'Error' : ''}`}
                  value={user.first_name.value}
                  onChange={(e) => handleUserInputChange('first_name', e)}
                  placeholder="First"
                />
                <InputBox
                  placeholder="Last"
                  className={`Right ${user.last_name.complete && user.last_name.error ? 'Error' : ''}`}
                  value={user.last_name.value}
                  onChange={(e) => handleUserInputChange('last_name', e)}
                />
              </div>
            </div>
          </div>

          <div className="Group">
            <div className="Title">Payment Details</div>

            {
              iCharge.error &&
              <div className="Form-Error">{iCharge.error}</div>
            }

            <div className="Field">
              <label>Card Information</label>
              <div className="Trio">
                <div className="Top StripeInput">
                  <CardNumberElement onChange={(e) => handleCardElementChange('number', e.complete, (e.error ? true : false))} />
                </div>
                <div className="Bottom">
                  <div className="Left StripeInput">
                    <CardExpiryElement onChange={(e) => handleCardElementChange('expiry', e.complete, (e.error ? true : false))} />
                  </div>
                  <div className="Right StripeInput">
                    <CardCvcElement onChange={(e) => handleCardElementChange('cvc', e.complete, (e.error ? true : false))} />
                  </div>
                </div>
              </div>
            </div>

            <div className="Field">
              <label>Name on Card</label>
              <InputBox
                className={card.name.complete && card.name.error ? 'Error' : ''}
                value={card.name.value}
                onChange={(e) => handleCardInputChange('name', e)}
              />
            </div>

            <div className="Field">
              <label>Country or Region</label>
              <div className="Duo-Vertical">
                <CountryDropdown
                  className={`Top ${card.country.complete && card.country.error ? 'Error' : ''}`}
                  value={card.country.value}
                  onChange={(e) => handleCardInputChange('country', e)}
                />
                <InputBox
                  placeholder="ZIP"
                  className={`Bottom ${card.zip.complete && card.zip.error ? 'Error' : ''}`}
                  value={card.zip.value}
                  onChange={(e) => handleCardInputChange('zip', e)}
                />
              </div>
            </div>
          </div>

          <div className="Submit">
            <ButtonBox
              className="Btn"
              text="Pay & Enroll"
              disabled={disableProceed()}
              onClick={(e) => {
                e.preventDefault();
                setProceed(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);
