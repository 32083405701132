import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import humanizeDuration from 'humanize-duration';

import Loader from '../../../Composed/Loader/Loader';
import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';

import { humanizeDate, humanizeTime } from '../../../../Util/Helper/GenericUtil';

import { clearLearnStats, fetchMyActivity } from '../../../../Actions/Stats/Users/LearnActions';

const mapStateToProps = (state) => ({
  activities: state.stats.users.learns?.myActivity || []
});

const mapDispatchToProps = (dispatch) => ({
  clearLearnStats: (key) => dispatch(clearLearnStats(key)),
  fetchMyActivity: () => dispatch(fetchMyActivity())
});

const MyActivity = ({ contentWidth, activities, clearLearnStats, fetchMyActivity }) => {
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState({ attribute: '', direction: -1 });

  let humanizeConfig = { units: ['d', 'h', 'm'], round: true, conjunction: ' & ' };

  useEffect(() => {
    clearLearnStats('myActivity');
    fetchMyActivity().then(
      res => setLoading(false)
    );
  }, [fetchMyActivity]);

  const applySort = (attribute) => {
    let newSort = JSON.parse(JSON.stringify(sort));

    if (newSort.attribute === attribute) {
      newSort.direction *= -1;
    } else {
      newSort.attribute = attribute;
      newSort.direction = 1;
    }

    setSort(newSort);
  }

  const sortedStats = () => {
    let activityStats = JSON.parse(JSON.stringify(activities));

    if (sort.attribute !== '') {
      activityStats.sort((a, b) => a[sort.attribute] > b[sort.attribute] ? (sort.direction * -1) : sort.direction);
    }

    return activityStats;
  }

  const sortAngle = (attribute) => (
    sort.attribute === attribute && sort.direction === 1 ? 'up' : 'down'
  )

  const renderMyActivity = () => (
    <div className="CardHolder">
      <div
        className="Content-Container Dynamic-Width"
        style={{
          width: `${contentWidth}px`
        }}
      >
        <table className="ActivityTable">
          <thead>
            <tr>
              <th
                onClick={() => applySort('date')}
              >
                Login Date
                <i className={`fa fa-angle-${sortAngle('date')}`} />
              </th>
              <th
                onClick={() => applySort('startTime')}
              >
                Start Time
                <i className={`fa fa-angle-${sortAngle('startTime')}`} />
              </th>
              <th
                onClick={() => applySort('endTime')}
              >
                End Time
                <i className={`fa fa-angle-${sortAngle('endTime')}`} />
              </th>
              <th
                onClick={() => applySort('duration')}
              >
                Duration
                <i className={`fa fa-angle-${sortAngle('duration')}`} />
              </th>
              <th>
                Last Lesson
              </th>
            </tr>
          </thead>
          <tbody>
            {
              sortedStats().map((activity, idx) => (
                <tr key={`my-activity-${idx}`}>
                  <td>{humanizeDate(activity.date)}</td>
                  <td>{humanizeTime(activity.startTime)}</td>
                  <td>{humanizeTime(activity.endTime)}</td>
                  <td>{humanizeDuration(activity.duration * 1000, humanizeConfig)}</td>
                  <td>
                    <span className="Link">
                      <Link to={`/learn/courses/${activity.lastLesson?.courseId}/lessons/${activity.lastLesson?.lessonId}/?nextToq=topics/${activity.lastLesson?.topicId}`}>
                        {activity.lastLesson?.name}
                      </Link>
                    </span>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )

  const renderEmpty = () => (
    <Link to="/learn" className="Start-Learning-Link">
      <ButtonBox
        className="Static-Primary-Btn"
        text="Start Learning!"
      />
    </Link>
  );

  const renderContent = () => {
    if (loading)
      return <Loader />;

    if (activities.length > 0)
      return renderMyActivity();

    return renderEmpty();
  }

  return (
    <div className="My-Activities-Container">
      <h2 className="Title">My Activity</h2>
      {renderContent()}
    </div>
  )
}

MyActivity.propTypes = {
  contentWidth: PropTypes.number.isRequired,
  activities: PropTypes.array.isRequired,
  clearLearnStats: PropTypes.func.isRequired,
  fetchMyActivity: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(MyActivity);
