import React, { Component } from 'react';
import { Prompt } from "react-router-dom";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './QuizPageV2.scss';
import MirrorLoader from '../../Composed/Loader/MirrorLoader';
import LessonAnswerButtons from '../../Composed/LessonAnswerButtons/LessonAnswerButtons';
import QuizSummaryBox from '../../Composed/QuizSummaryBox/QuizSummaryBox';
import TextContentBox from '../../Presentational/TextContentBox/TextContentBox';
import MultiButtonChoiceBox from '../../Quiz/MultiButtonChoiceBox/MultiButtonChoiceBox';
import MultiButtonAnswerBox from '../../Quiz/MultiButtonAnswerBox/MultiButtonAnswerBox';
import FillInTheBlankBox from '../../Quiz/FillInTheBlankBox/FillInTheBlankBox';
import TrueOrFalseBox from '../../Quiz/TrueOrFalseBox/TrueOrFalseBox';
import DropDownBox from '../../Quiz/DropDownBox/DropDownBox';
import DragAndDropBox from '../../Quiz/DragAndDropBox/DragAndDropBox';
import MatchingBox from '../../Quiz/MatchingBox/MatchingBox';
import SentenceOrderingBox from '../../Quiz/SentenceOrderingBox/SentenceOrderingBox';
import WordScrambleBox from '../../Quiz/WordScrambleBox/WordScrambleBox';
import AudioPlayButton from '../../Quiz/AudioPlayButton/AudioPlayButton';
import AudioProgressBox from '../../Basic/AudioProgressBox/AudioProgressBox';
import SpanishKeyboard from '../../Composed/SpanishKeyboard/SpanishKeyboard';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import CourseLeftover from '../../Composed/CourseLeftover/CourseLeftover';

import { instructionsContent } from '../../../Util/Helper/GenericUtil';
import { sanitizeHtmlString } from '../../../Util/Helper/SanitizeUtil';
import { hasAnsweredAll } from '../../../Util/Helper/QuestionAnswerUtil';
import { continueLessonLink } from '../../../Util/Helper/CourseUtil';
import { gaEvent } from '../../../Util/Helper/GoogleUtil';

class QuizPageV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSectionIndex: 0,
      currentQuestionIndex: 0,
      slide: true,
      summary: false,
      displayBookmarkSection: true,
      loadingQuiz: true,
      loadingQuizResults: true,
      submitting: false,
      quizResultId: null,
      error: ''
    };
    this.blockNavigation = true;
    this.fullListingSections = ['matching', 'drag_and_drop', 'sentence_ordering'];
  }

  componentDidMount() {
    let queryString = new URLSearchParams(this.props.location.search);
    const {
      courseId, course, lessonId, lesson, lessons,
      clearQuizProgresses, clearQuizStates, clearQuizResults,
      fetchQuiz, fetchQuizResults,
      receiveSectionIndex, receiveQuestionIndex,
    } = this.props;

    clearQuizProgresses();
    clearQuizStates();
    clearQuizResults();
    fetchQuizResults().then(res => this.setState({ loadingQuizResults: false }));
    fetchQuiz(lessonId).then(res => {
      if (res.payload?.status == 'archive') {
        this.props.history.push(continueLessonLink(course, lesson, null, null, lesson.quiz_id, lessons));
      } else {
        this.setState({ loadingQuiz: false });
      }
      if (queryString.has('copn'))
        document.getElementsByClassName('Comments-Button')[0].click();
    });
    this.setState({ currentSectionIndex: queryString.has('nextSec') ? parseInt(queryString.get('nextSec')) : 0, currentQuestionIndex: 0 });
    receiveSectionIndex(queryString.has('nextSec') ? parseInt(queryString.get('nextSec')) : 0);
    receiveQuestionIndex(0);
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentDidUpdate(prevProps) {
    const {
      courseId, course, lessonId, lesson, lessons,
      clearQuizProgresses, clearQuizStates, clearQuizResults,
      fetchQuiz, fetchQuizResults,
      receiveSectionIndex, receiveQuestionIndex,
    } = this.props;

    if (prevProps.lessonId !== lessonId) {
      this.setState({ loadingQuiz: true, loadingQuizResults: true, quizResultId: null });
      clearQuizProgresses();
      clearQuizStates();
      clearQuizResults();
      fetchQuizResults().then(res => this.setState({ loadingQuizResults: false }));
      fetchQuiz(lessonId).then(res => {
        if (res.payload?.status == 'archive') {
          this.props.history.push(continueLessonLink(course, lesson, null, null, lesson.quiz_id, lessons));
        } else {
          this.setState({ loadingQuiz: false });
        }
      });
      this.setState({ slide: true, summary: false, currentSectionIndex: 0, currentQuestionIndex: 0 });
      receiveSectionIndex(0);
      receiveQuestionIndex(0);
      this.blockNavigation = true;
      window.addEventListener('beforeunload', this.onUnload);
    }
  }

  componentWillUnmount() {
    this.props.clearSectionIndex();
    this.props.clearQuestionIndex();
    window.removeEventListener('beforeunload', this.onUnload);
  }

  onUnload = (e) => {
    e.preventDefault();
    e.returnValue = true;
  }

  handleInstructions = () => {
    const { quizzes, lesson, course } = this.props;
    const { currentSectionIndex } = this.state;

    const quiz = quizzes[lesson.quiz_id];
    const currentSection = quiz.sections[currentSectionIndex];

    let finalInstructions = '';

    if (course?.course_type?.name === 'Compliance') {
      finalInstructions = currentSection.instructions;
    } else {
      finalInstructions = currentSection.instructions ? (`<p>${currentSection.position}. ${currentSection.name}</p><br>${currentSection.instructions}`) : (`${currentSection.position}. ${currentSection.name}`);
    }

    return (
      finalInstructions && instructionsContent(finalInstructions) &&
      <div className="LearningPage-Instructions">
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(finalInstructions) }}
        />
      </div>
    )
  }

  setSectionInstructionAudio = section => () => {
    if (!section)
      return null;

    document.getElementsByClassName('AudioProgressBox-Error')[0].innerText = '';

    let listened = JSON.parse(window.localStorage.getItem('sectionInstructionAudios')) || [];

    if (listened.includes(section.id))
      return null;

    listened.push(section.id)
    window.localStorage.setItem('sectionInstructionAudios', JSON.stringify(listened));
  }

  getSectionInstructionAudio = section => {
    let listened = JSON.parse(window.localStorage.getItem('sectionInstructionAudios')) || [];
    return listened.includes(section.id);
  }

  isBookmarkedSection = section => {
    const { bookmarks } = this.props;

    if (!section) {
      return false;
    }

    return bookmarks?.ids?.sections?.includes(section.id);
  }

  bookmarkSection = section => () => {
    const {
      createBookmark,
      deleteBookmark
    } = this.props;

    const bookmark = { bookmarkable_type: 'Section', bookmarkable_id: section.id };
    if (this.isBookmarkedSection(section)) {
      gaEvent('unbookmark_slide', { section_id: section.id });
      deleteBookmark(bookmark);
    } else {
      gaEvent('bookmark_slide', { section_id: section.id });
      createBookmark(bookmark);
    }
  }

  handleRender() {
    const { course, quizzes, lessonId, lesson, quizProgress, recordQuizAnswer, recordQuizState, quizStates } = this.props;
    const { currentSectionIndex, currentQuestionIndex } = this.state;

    const quiz = quizzes[lesson.quiz_id];
    const currentSection = quiz.sections[currentSectionIndex];
    let component;

    const newProps = {
      lessonId,
      section: currentSection,
      questions: currentSection.questions,
      recordQuizAnswer,
      recordQuizState,
      quizProgress,
      quizStates,
      isComplianceCourse: (course?.course_type?.name === 'Compliance')
    }

    if (currentSection.questions.length) {
      switch (currentSection.section_type) {
        case 'multiple_choice':
          component = <div className="MultiButtonChoiceContainer"><MultiButtonChoiceBox currentQuestionIndex={currentQuestionIndex} {...newProps} /></div>
          break;
        case 'multiple_answer':
          component = <div className="MultiButtonAnswerContainer MultiButtonChoiceContainer"><MultiButtonAnswerBox currentQuestionIndex={currentQuestionIndex} {...newProps} /></div>
          break;
        case 'fill_in_the_blank':
          component = <div className="FillInTheBlankContainer"><FillInTheBlankBox currentQuestionIndex={currentQuestionIndex} {...newProps}  /></div>
          break;
        case 'true_or_false':
            component = <div className="TrueOrFalseContainer"><TrueOrFalseBox currentQuestionIndex={currentQuestionIndex} {...newProps} /></div>
          break;
        case 'drop_down':
          component = <div className="DropDownContainer"><DropDownBox currentQuestionIndex={currentQuestionIndex} {...newProps} /></div>
          break;
        case 'word_scramble':
          component = <div className="FillInTheBlankContainer"><WordScrambleBox currentQuestionIndex={currentQuestionIndex} {...newProps} /></div>
          break;
        case 'drag_and_drop':
          component = <DragAndDropBox { ...newProps } />;
          break;
        case 'matching':
          component = <MatchingBox { ...newProps } />;
          break;
        case 'sentence_ordering':
          component = <SentenceOrderingBox { ...newProps } />;
          break;
        default:
          return null;
      }
    } else {
      component = currentSection.section_type == 'text' ? '' : <div>This section is incomplete. It contains no questions.</div>;
    }

    if ((currentSection.text_contents.length === 0) && !currentSection.audio_url && !component) {
      return ''
    }

    return (
      <div className={`QuizPage-Content ${currentSection.section_type}`}>
        {
          (currentSection.text_contents.length > 0) &&
          <div className="QuizPage-TextBox">
            <TextContentBox { ...newProps } />
          </div>
        }

        {
          currentSection.audio_url &&
          <div className="QuizPage-AudioPlayButton">
            <AudioPlayButton
              className="AudioPlayButton-QuizPage"
              src={currentSection.audio_url}
            />
          </div>
        }

        {
          currentSection.section_type === 'fill_in_the_blank'
          && course.language === 'SP'
          && <SpanishKeyboard />
        }

        { component }
      </div>
    );
  }

  submitAnswer = () => {
    const { quizzes, lesson, quizProgress, submitQuizV2, receiveSectionIndex, receiveQuestionIndex, currentUser } = this.props;
    const { currentSectionIndex, currentQuestionIndex, quizResultId } = this.state;

    const quiz = quizzes[lesson.quiz_id];
    const currentSection = quiz.sections[currentSectionIndex];
    const currentQuestion = currentSection.questions[currentQuestionIndex];
    const isFullListingSection = this.fullListingSections.includes(currentSection.section_type);
    const isLastSectionOfQuiz = quiz.sections.length === (currentSectionIndex + 1);
    const isLastQuestionOfSection = currentSection.questions.length === (currentQuestionIndex + 1);

    if (false && currentSection && currentSection.instruction_audio_url && !this.getSectionInstructionAudio(currentSection)) {
      document.getElementsByClassName('AudioProgressBox-Error')[0].innerText = 'Please listen to the full audio before moving on.'
      return null;
    }

    if (currentSection.section_type === 'text') {
      if (isLastSectionOfQuiz) {
        this.takeToSummary();
      } else {
        this.setState({ currentSectionIndex: currentSectionIndex + 1, currentQuestionIndex: 0 });
        receiveSectionIndex(currentSectionIndex + 1);
        receiveQuestionIndex(0);
      }
      return null;
    }

    if (!hasAnsweredAll(currentSection, currentQuestion, isFullListingSection, quizProgress)) {
      this.setState({ error: 'Please answer the question(s) to proceed.' });
      let scrollElement = document.getElementById('Answered-All-Error-Container');
      window.scrollTo({ top: (scrollElement.offsetTop - 100), behavior: 'smooth' });
      return null;
    }

    let numQuestions = 0;
    let numCorrect = 0;

    quiz.sections.forEach(section => {
      section.questions.forEach(question => {
        numQuestions += question.answers.length;
      });
    });

    if (quizProgress) {
      Object.values(quizProgress).forEach(sectionProgress => {
        Object.values(sectionProgress).forEach(questionProgress => {
          Object.values(questionProgress).forEach(answerProgress => {
            if (answerProgress.is_correct) {
              numCorrect++;
            }
          });
        });
      });
    }

    const quizData = {
      user_id: currentUser.id,
      quiz_id: quiz.id,
      num_correct: numCorrect,
      num_questions: numQuestions,
      quiz_data: quizProgress,
      quiz_result_id: quizResultId,
    }

    this.setState({ submitting: true });
    submitQuizV2(quizData).then(res => {
      if (res?.errors?.message) {
        return this.setState({ error: 'Oops! something went wrong. Please try again.', submitting: false });
      }

      gaEvent('quiz_answer_submit', { quiz_id: quiz.id });
      this.setState({ quizResultId: res.payload.quizResultId, submitting: false });

      if (isLastSectionOfQuiz && (isFullListingSection || (isLastQuestionOfSection))) {
        this.takeToSummary();
        return null;
      }

      if (isFullListingSection || isLastQuestionOfSection) {
        this.setState({
          currentSectionIndex: (currentSectionIndex + 1),
          currentQuestionIndex: 0,
          error: ''
        });
        receiveSectionIndex(currentSectionIndex + 1);
        receiveQuestionIndex(0);
      } else {
        this.setState({ currentQuestionIndex: (currentQuestionIndex + 1), error: '' });
        receiveQuestionIndex(currentQuestionIndex + 1);
      }
    });
  }

  takeToSummary = () => {
    window.removeEventListener('beforeunload', this.onUnload);
    this.blockNavigation = false;
    this.setState({
      slide: false,
      summary: true,
      quizResultId: null,
      error: ''
    });
    this.props.receiveSectionIndex(null);
  }

  quizCantLoad = () => {
    window.removeEventListener('beforeunload', this.onUnload);
    this.blockNavigation = false;

    const { receiveSectionIndex, receiveQuestionIndex, courseId, course, lesson, lessonId, lessons } = this.props;

    return (
      <div className="Quiz-Cant-Load">
        <p className="Message">You have already attempted this quiz.</p>
        <Link to={continueLessonLink(course, lesson, null, null, lesson.quiz_id, lessons)} onClick={() => {
          receiveSectionIndex(0);
          receiveQuestionIndex(0);
        }}>
          <ButtonBox
            className="QuizAnswerButtons-ButtonBox Green-Btn"
            text={(
              <div className="Green-Btn-Text">
                Continue
                <i className="fas fa-chevron-right" />
              </div>
            )}
          />
        </Link>
      </div>
    )
  }

  render() {
    const { courseId, course, courseProgresses, lessonId, lesson, quizzes, quizResults  } = this.props;
    const { slide, loadingQuiz, loadingQuizResults, submitting, summary, currentSectionIndex, error } = this.state;

    if (loadingQuiz || loadingQuizResults) {
      return <MirrorLoader message="Loading, Hang on!" />
    }

    const quiz = quizzes[lesson?.quiz_id];

    if (!quiz?.sections || !quiz.sections.length) {
      return <div className="QuizPage" />;
    }

    if ((quizResults[quiz.id]?.length > 1) || (quizResults[quiz.id]?.length === 1 && !quizResults[quiz.id][0].quizResultId)) {
      return this.quizCantLoad();
    }

    const currentSection = quiz.sections[currentSectionIndex];

    return (
      <div className="QuizPage-Backdrop">
        <Prompt
          when={this.blockNavigation}
          message="Are you sure you want to leave? Leaving will save the current progress and you will not be able to re-attempt this quiz."
        />

        {submitting ? <MirrorLoader message={'Saving Answer(s)'} /> : ''}

        <div className="QuizPage">
          {
            lessonId === course.lessons[course.lessons.length - 1].toString() &&
            <CourseLeftover
              course={course}
              courseProgress={courseProgresses[courseId]}
              quizResults={quizResults}
            />
          }
          <div className="LearningPage-LessonAnswerButtons_container">
            {
              (slide) && (
                <LessonAnswerButtons
                  currentUser={this.props.currentUser}
                  quiz={quiz}
                  currentSection={currentSection}
                  displayBookmarkSection={this.state.displayBookmarkSection}
                  isBookmarkedSection={this.isBookmarkedSection(currentSection)}
                  bookmarkSection={this.bookmarkSection(currentSection)}
                  displayRightClick={true}
                  rightClickHandler={() => this.submitAnswer()}
                />
              )
            }

            { slide && this.handleInstructions() }

            <div id="Answered-All-Error-Container">
              {
                error && <div id="Answered-All-Error">{error}</div>
              }
            </div>

            {
              slide && currentSection?.instruction_audio_url &&
              <AudioProgressBox
                src={currentSection.instruction_audio_url}
                onEndHandler={this.setSectionInstructionAudio(currentSection)}
              />
            }

          </div>

          {
            summary ? (
              <QuizSummaryBox
                courseId={courseId}
                lessonId={lessonId}
                quiz={quiz}
              />
            ) : (
              this.handleRender()
            )
          }
        </div>
      </div>
    )
  }
}

export default QuizPageV2;
