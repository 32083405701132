import './CanopyAdminDashboard.css';
import React from 'react';
import { AdminRoute } from '../../../Util/RouteUtil';
import CreateEditInstitutionContainer from '../../Forms/CreateEditInstitution/CreateEditInstitutionContainer';
import AdminDashboardInstitutions from './AdminDashboardInstitutions/AdminDashboardInstitutionsContainer';
import AdminDashboardCoursePackages from './AdminDashboardCoursePackages/AdminDashboardCoursePackagesContainer';
import AdminDashboardLicenses from './AdminDashboardLicenses/AdminDashboardLicensesContainer';
import CoursePackageDashboard from './CoursePackageDashboard/CoursePackageDashboardContainer';
import CreateLicenseContainer from '../../Forms/CreateLicense/CreateLicenseContainer';
import CreateUser from '../../Forms/CreateUser/CreateUserContainer';
import ModifyUser from '../../Forms/ModifyUser/ModifyUserContainer';
import InviteUsers from '../../Forms/InviteUsers/InviteUsersContainer';
import ManageUserAccess from '../../Forms/ManageUserAccess/ManageUserAccessContainer';
import InstitutionDetail from './AdminDashboardInstitutions/InstitutionDetail';
import LicenseDetail from './AdminDashboardLicenses/LicenseDetail';
import AdminSideBar from '../../SideBars/AdminSideBar/AdminSideBar';
import ManageAllComments from '../../Forms/ManageAllComments/ManageAllComments';
import ManageExams from './AdminDashboardCredential/ManageExams/ManageExams';
import GrantAccessContainer from './AdminDashboardCredential/GrantAccess/GrantAccessContainer';
import ManageTestsContainer from './AdminDashboardCredential/ManageTests/ManageTestsContainer';
import StatsInstitutionsContainer from './AdminDashboardStats/Institutions/InstitutionsContainer';
import StatsUsersContainer from './AdminDashboardStats/Users/UsersContainer';
import CreateUserResourceContainer from '../../Forms/UserResources/CreateUserResource/CreateUserResourceContainer';
import InstitutionResourceManagementContainer from '../../Pages/InstitutionResources/Management/ManagementContainer';

const CanopyAdminDashboard = () => (
    <div className="Admin-Dashboard">
        <AdminSideBar />
        <div className="Admin-Dashboard-Main">
            <AdminRoute
                path="/account/institutions/new"
                component={CreateEditInstitutionContainer}
            />

            <AdminRoute
                path="/account/license/new"
                component={CreateLicenseContainer}
            />

            <AdminRoute
                exact
                path="/account/institutions/:institutionId/edit"
                component={InstitutionDetail}
            />

            <AdminRoute
                exact
                path="/account/institutions"
                component={AdminDashboardInstitutions}
            />

            <AdminRoute
                exact
                path="/account/stats/institutions"
                component={StatsInstitutionsContainer}
            />

            <AdminRoute
                exact
                path="/account/stats/users"
                component={StatsUsersContainer}
            />

            <AdminRoute
                exact
                path="/account/resource_library"
                component={InstitutionResourceManagementContainer}
            />

            <AdminRoute
                exact
                path="/account/licenses/:licenseId"
                component={LicenseDetail}
            />

            <AdminRoute
                exact
                path="/account/licenses"
                component={AdminDashboardLicenses}
            />

            <AdminRoute
                path="/account/course_packages/:coursePackageId"
                component={CoursePackageDashboard}
            />

            <AdminRoute
                exact
                path="/account/course_packages"
                component={AdminDashboardCoursePackages}
            />

            <AdminRoute
                exact
                path="/account/comments"
                component={ManageAllComments}
            />

            <AdminRoute
                exact
                path="/account/invite_users"
                component={InviteUsers}
            />
            <AdminRoute
                exact
                path="/account/create_user"
                component={CreateUser}
            />
            <AdminRoute
                exact
                path="/account/modify_user"
                component={ModifyUser}
            />
            <AdminRoute
                exact
                path="/account/user_access"
                component={ManageUserAccess}
            />
            <AdminRoute
                exact
                path="/account/credential/manage_exams"
                component={ManageExams}
            />
            <AdminRoute
                exact
                path="/account/credential/grant_access"
                component={GrantAccessContainer}
            />
            <AdminRoute
                exact
                path="/account/credential/manage_tests"
                component={ManageTestsContainer}
            />
            <AdminRoute
                exact
                path="/account/user_resources/new"
                component={CreateUserResourceContainer}
            />
        </div>
    </div>
);


export default CanopyAdminDashboard;
